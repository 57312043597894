import React,{Component} from 'react';
import {AppContext} from '../Context';

import {toast} from "react-toastify";
import {Redirect,Link } from "react-router-dom"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import user_profile from '../Img/user_profile.jpg';
let BaseURL = 'https://boescify.com/uploads/';
class Dashboard extends Component {
  static contextType = AppContext;
       constructor(props) {
              super(props);
              this.state = {
                };
             
            }

        
componentDidMount(){
  this.fetchregister();
 }
 fetchregister = () => {
  this.context.fetchregister(this.props.storageid);
}  

businessview = (url) => {
       if (!!url && !!url.trim()) { //Check if url is not blank
              url = url.trim(); //Removes blank spaces from start and end
              if (!/^(https?:)?\/\//i.test(url)) { //Checks for if url doesn't match either of: http://example.com, https://example.com AND //example.com
                  url = 'http://' + url; //Prepend http:// to the URL
                 // window.location = url;
                  window.open(url, '_blank');
              }else{
                     window.open(url, '_blank');
              }
          } else {
              //Handle empty url
             
          }

         
     }  

          render(){
            let allUsers;
            let mainData;
            allUsers = this.context.all_active.map(({register_id,name,Phone_Number,gender,	About_Me,profile_image,member,status,facebook,twitter,linkedin}) => {
             
            return ( 
                <div key={register_id}>
                    {status !=='Active' ?  <>  {toast(" Please Active Membership Plan ", { position: "top-center" })} <Redirect to={`/c453a4b8e8d98e82f35b67f433e3b4da/${register_id}`} />
                        </> : null}
                        <div className="col-md-3" >
                            <div className="change-avatar2">
                            {profile_image !=='' ? 
                                   <img src={BaseURL+profile_image} />
                                   :
                                   <img src={user_profile} alt="" />
                             }
                                  
                            </div>
                         </div>
                        <div className="col-md-4" >
                             <div className="bbb theme-btn2 theme-btn style-one">
                                    <div className="elementor2">
                                           <h3> Membership Id </h3>
                                           <h3> 30{register_id} </h3>
                                         
                                                 
                                    </div>
                            </div>
                     </div>
                     <div className="col-md-5" >
                            <div className="bbb">
                                   <div className="elementor">
                                          <h3> Membership Plan </h3>
                                          {member === 'Sliver' ? 
                                          <h4> Silver </h4>
                                          :
                                          <h4> {member} </h4>
                                         } 
                                         
                                   </div>
                            </div>
                     </div> 
                     <div className="clearfix"></div>
                     <div className="col-md-12" >
                     <ul className="linksss">
                       
                            <li className="menu-item" onClick={this.toggleClass}><Link to="/16fe4ae43ecb50933e1c67d26612a11d" className="theme-btn style-one"><FontAwesomeIcon icon={['fas','file-contract']} className="" /> Classified Ads </Link></li>
                           <li className="menu-item" onClick={this.toggleClass}><Link to="/722f967d25445b42c87118d0b2268c94" className="theme-btn style-two"><FontAwesomeIcon icon={['fas','briefcase']} className="" />  Business Directory </Link></li>

                           {member !=='Platinum' ? 
                                   <li className="menu-item" onClick={this.toggleClass}><Link to={`/aaaee1bc14675c64b11b786a6a3e3555/${register_id}` }className="theme-btn style-three"><FontAwesomeIcon icon={['fas','seedling']} className="" /> Upgrade Plan </Link></li>
                           : null }
                          
                        </ul>
                        </div> 
                        <div className="clearfix"></div>
                      
                            <ul className="linksprosss2 ">
                                   <li><FontAwesomeIcon icon={['fab','facebook-square']} className="chooseus3 chooseus3facebook" onClick={() => this.businessview(facebook)} /></li>
                                   <li><FontAwesomeIcon icon={['fab','twitter-square']} className="chooseus3 chooseus3twitter" onClick={() => this.businessview(twitter)} /></li>
                                   <li><FontAwesomeIcon icon={['fab','linkedin']} className="chooseus3 chooseus3linkedin" onClick={() => this.businessview(linkedin)} /></li>
                            
                            </ul>
                          
                       <div className="clearfix"></div>
                            
                    
                     <div className="col-md-12" >
                     <ul className="linksprosss">
                    
                   
                            <li className="menu-itemqqq" > <b> Name : </b> {name} </li>
                            {gender !=='' ?  
                            <li className="menu-itemqqq" > <b> Gender  : </b> {gender} </li>
                            :null }
                             {Phone_Number !=='' ?  
                            <li className="menu-itemqqq" > <b> Phone Number : </b> {Phone_Number} </li>
                            :null }
                            {About_Me !=='' ?  
                            <li className="menu-itemqqq" > <b> About : </b> {About_Me} </li>
                            :null }
                            
                        </ul>
                        </div>  
              </div>
              )
            });
            if(this.context.all_active.length > 0){
              mainData = ( <div>{allUsers}  </div> );
          }
            
              return (
                  <>
               
                     {mainData}
                     
                    
              </>  
             
              );
          }
      }

export default Dashboard;
