import React, { Component } from 'react';

import {Link } from "react-router-dom"; 

import Axios from 'axios';

let BaseURL = 'https://boescify.com/';

class Success extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      paypaldetails:[],
    };
  }
  componentDidMount(){
    this.successdata(this.props.match.params.successid);
    window.scrollTo(0, 0);
  }  
  successdata(paymentid){
    return Axios.post(BaseURL+'viewall/paypalviews',{
      paymentid:paymentid,
       })
       .then(function ({data}) {
        if(data.success === 1){this.setState({ paypaldetails:data.paypallist }); }
    }.bind(this))
    .catch(function (error) { console.log(error); });    
}

  render(){
    const {paypaldetails} = this.state;
    return (
      <>
       <div className="breadcrumbs">
              <div className="bubble-dotted">
                <span className="dotted dotted-1"></span>
                <span className="dotted dotted-2"></span>
                <span className="dotted dotted-3"></span>
                <span className="dotted dotted-4"></span>
                <span className="dotted dotted-5"></span>
                <span className="dotted dotted-6"></span>
                <span className="dotted dotted-7"></span>
                <span className="dotted dotted-8"></span>
                <span className="dotted dotted-9"></span>
                <span className="dotted dotted-10"></span>
              </div>
              <div className="container">
              <div className="content-box">
                  <h1> Payment Receipt </h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/"> Home </Link></li>
                      <li> Payment Receipt </li>
                  </ul>
              </div>
          </div>
        </div>
      <div className="successs">
          <div className="container">
            <div className="row">
               <div className="col-md-12">
        <div className="container-fluid">
        <div className="col-md-1">
                        &nbsp;
              </div>
              <div className="col-md-10 logsss3">
             <h2>Your Payment Process Checking.</h2>
          
             {paypaldetails.map((item, index) => (
                    <div key={item.pay_id}>

                  {item.payment_status === 'completed' ? 
                        <> 
                          <h3> Order No : {item.member_id} </h3>    
                          <h3> PayPal transaction id :  {item.txn_id} </h3>    
                          <h3> Total Amount: {item.payment_gross} </h3>   
                          <h3> Currency: {item.currency_code} </h3>  
                          <h3> Payment Status : {item.payment_status} </h3> 
                          <Link to={`/9d6adc5f7a6f7a9311bf8cfebad6f84f`} className="theme-btn style-two" > Go To Dashboard </Link>
                        </>
                         : 
                       <> 
                          <h3> Order No : {item.member_id} </h3>    
                          <h3> Total Amount: {item.payment_gross} </h3>   
                          <h3> Currency: {item.currency_code} </h3>  
                          <h3 style={{color: "#fd2e1f"}}> Payment Status : {item.payment_status} </h3> 
                          <Link to={`/c453a4b8e8d98e82f35b67f433e3b4da/${item.member_id}`} className="theme-btn style-two" > Payment Again </Link>
                       </>
                       }       
                           
                          
                                
                    </div>

                      ))}
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
      </>
    );
  }
}

export default Success;