import React, { Component } from 'react';

import {AppContext} from '../Context';
import Loader from 'react-loader-spinner';
import user_profile from '../Img/user_profile.jpg';

let BaseURL = 'https://boescify.com/uploads/';
class Profile extends Component {
       
    static contextType = AppContext;
    constructor(props) {
       super(props);
       this.state = {
            file: null,
            isEditing:false,
            invalidImage: null,
            showResults: false,
            fileempty:false,
         };
       this.onChange = this.onChange.bind(this);
       this.resetFile = this.resetFile.bind(this);
        this.reader = new FileReader();
}

    componentDidMount(){
       this.fetchregister();
      }
      fetchregister = () => {
       this.context.fetchregister(this.props.storageid);
     }   

   //image preview       
   onChange(event) {
       //  this.setState({
       //        file: URL.createObjectURL(event.target.files[0])
       //  });

        const imageFile = event.target.files[0];
        const imageFilname = event.target.files[0].name;

        if (!imageFile) {
          this.setState({ invalidImage: 'Please select image.' });
          return false;
        }
    
        if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
          this.setState({ invalidImage: 'Please select valid image JPG,JPEG,PNG,GIF' });
          return false;
        }
        this.reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
                 //----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 727;
                var MAX_HEIGHT = 727;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  this.setState({Resizeimg: file});
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
            this.setState({
              selectedFile: imageFile,
              file: URL.createObjectURL(imageFile),
              fileempty:true,
              invalidImage: null
               });
          };
          img.onerror = () => {
            this.setState({ invalidImage: 'Invalid image content.' });
            return false;
          };
          debugger
          img.src = e.target.result;
        };
        this.reader.readAsDataURL(imageFile);
        

        

  }
  resetFile(event) {
         event.preventDefault();
         this.setState({ file: null });
       }
       profileupdate = (storageid) => {
              this.context.profileupdate(storageid,this.state.Resizeimg,this.names.value,this.gender.value,this.phone.value,this.about.value,this.fileempty.value);
       }


    render(){
       const {invalidImage} = this.state;
       const {  loadingchangepassword} = this.props;
       let allUsers;
            let mainData;
            allUsers = this.context.all_active.map(({register_id,profile_image,name,About_Me,Phone_Number,gender,status}) => {
             
            return ( 
                <div key={register_id}>
                  
                  
               
                            <h4 className="card-title2"> Profile Settings </h4>
                            <div className="row">
                                   <div className="col-md-3">
                                          <div className="change-avatar">
                                          {this.state.file ? (
                                          <> <img className=""  src={this.state.file} alt="UploadImage" /> </>
                                                 ) : (  <>  
                                                 
                                                 {profile_image !=='' ? 
                                                        <img src={BaseURL+profile_image} /> 
                                                        :
                                                        <img src={user_profile} alt="" />
                                                 }
                                                 
                                                   
                                                 </>)}
                                             
                                          </div>
                                   </div>
                                   <div className="col-md-9">
                                        
                                          <div className="form-group col-md-12">
                                                 <label className="font-weight-bold"> Name <span className="adverts-form-required"> * </span> </label>
                                                 <input type="text" name="names" ref={(val) => this.names = val} className="form-control"  placeholder="Name" defaultValue={name} required/>
                                          </div>
                                          <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Image  </label>
                          <input type="file" name="file" ref={(val) => this.file = val} className="form-control" placeholder="Title"  onChange={this.onChange} />
                          {invalidImage && <p className="error">{invalidImage}</p>}

                          {this.state.fileempty === false ? 

                            <input type="hidden" name="fileempty" ref={(val) => this.fileempty = val} className="form-control" defaultValue="emptyimgfields" />
                            : 
                            <input type="hidden" name="fileempty" ref={(val) => this.fileempty = val} className="form-control" defaultValue="activeimgfields" />
                            }


                      </div>
                      {this.state.file && (
                        <div style={{ textAlign: "center" }}>
                             <img className="previewimg"  src={this.state.file} alt="UploadImage" />
                            <button onClick={this.resetFile} className="removeqqq">Remove File</button>
                        </div>
                        ) }
                        
                                          <div className="form-group col-md-12">
                                           <label className="font-weight-bold"> Gender (optional) </label>
                                          <select  name="gender" ref={(val) => this.gender = val}  className="wqsss form-control"  defaultValue={gender} >
                                  
                                          <option value=""> Choose the field </option>
                                          <option>Male</option>
                                          <option>Female</option>
                                          </select>
                                          </div>
                                          <div className="form-group col-md-12">
                                                 <label className="font-weight-bold"> Phone Number (optional) </label>
                                                 <input type="text" name="phone" ref={(val) => this.phone = val} className="form-control"  placeholder="Phone Number" defaultValue={Phone_Number} />
                                          </div>
                                          <div className="form-group col-md-12">
                                                 <label className="font-weight-bold"> About Me (optional) </label>
                                                 <textarea  name="about" ref={(val) => this.about = val} className="form-control" rows="5"  placeholder="About Me" defaultValue={About_Me} />
                                          </div>
                                          <div className="form-group col-md-12 text-right">
                                          {loadingchangepassword ?
                                                 <>
                                                        <Loader type="ThreeDots" color="#00BFFF" height={77} width={77} style={{float: "left"}}/>
                                                 </>
                                                 : 
                                                 <button type="submit" className="theme-btn style-one" onClick={() => this.profileupdate(this.props.storageid)}> Save </button>
                                                
                                          } 
                                          </div>
                                         
                                       
                                   </div>
                            </div>
            


                    
              </div>
              )
            });
            if(this.context.all_active.length > 0){
              mainData = ( <div>{allUsers}  </div> );
          }
            
        return(
              <>
                     {mainData}
               
          </>  
        );
    }
}
export default Profile;