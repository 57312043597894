import React, { Component } from 'react';

import Loader from 'react-loader-spinner';

import {Link } from "react-router-dom"; 
import { toast } from "react-toastify";
import Axios from 'axios';

import StripeCheckout from "react-stripe-checkout";

import paypal from '../Img/logo-paypal.png';
import stripe from '../Img/stripe.png';

let BaseURL = 'https://boescify.com/';

class Payment extends Component {
       
       constructor(props) {
              super(props);
              this.state = {
                isLoading: false,
                paynowpaypal: false,
                paynowstripe: true,
                pricealllist:[],
                Sliver:'',
                Gold:'',
                Platinum:'',
              };
              this.packageselected = this.packageselected.bind(this);
            }
  componentDidMount(){
         this.fetchregister(this.props.match.params.memberid);
         this.fetchprice();
         window.scrollTo(0, 0);
  }  
  
  

  fetchprice(){
       return Axios.get(BaseURL+'viewall/price')
          .then(function ({data}) {
             console.log(data.priceall[0].Gold);
           if(data.success === 1){ this.setState({ pricealllist: data.priceall,
            Sliver:data.priceall[0].Sliver,
            Gold:data.priceall[0].Gold,
            Platinum:data.priceall[0].Platinum,
         
         }); }
            
       }.bind(this))
       .catch(function (error) { console.log(error); });    
  }
  fetchregister(registerid){
       return Axios.post(BaseURL+'viewall/register',{
              registerid:registerid,
          })
          .then(function ({data}) {
           if(data.success === 1){this.setState({ packagePlan:data.memberlist[0].member,memberid:data.memberlist[0].register_id }); }
       }.bind(this))
       .catch(function (error) { console.log(error); });    
  }

  packageselected(event) {
       //alert(event.target.value);
       this.setState({
         packagePlan: event.target.value
       });
     }

  
     paypalMode = () => {
       this.setState({
           paynowpaypal: true,
           paynowstripe: false
       });

   }
   stripeMode = () => {
       this.setState({
           paynowpaypal: false,
           paynowstripe:true
       });

   }
     

   makePayment = (token) => {
     
      const registerid =  this.props.match.params.memberid;
     const{ Sliver, Gold, Platinum } = this.state;
      if(this.state.packagePlan === 'Sliver'){
         this.setState({ membershipprice: Sliver, membershipplan:'Sliver'});
       
      }else if(this.state.packagePlan === 'Gold'){
         this.setState({ membershipprice: Gold, membershipplan:'Gold'});
      }
      else if(this.state.packagePlan === 'Platinum'){
         this.setState({ membershipprice: Platinum, membershipplan:'Platinum'});
      }
     
      const  membership =this.state.membershipprice;
      const  membershipplan =this.state.membershipplan;
      
     const body = { token, registerid, membership, membershipplan };
      const headers = {
      "Content-Type": "application/json"
      };
   
      return fetch(BaseURL+`stripe/payment`, {
      method: "POST",
      headers,
      body: JSON.stringify(body)
      })
      .then(res => {
         //console.log(res.url);
         if(res.status === 200){
            window.location.href = res.url; 
           // this.props.history.push(`/e5ed76de5cc54c37ff7adf73156002b7/${this.props.match.params.memberid}`)
         } else {
            toast.error('Please Try Again', {
               position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
             });
           
         }
      })
      .catch(error => console.log(error));
      };

      

    render(){
           const {pricealllist,memberid} = this.state;
        return(
              <>
               <div className="breadcrumbs">
              <div className="bubble-dotted">
                <span className="dotted dotted-1"></span>
                <span className="dotted dotted-2"></span>
                <span className="dotted dotted-3"></span>
                <span className="dotted dotted-4"></span>
                <span className="dotted dotted-5"></span>
                <span className="dotted dotted-6"></span>
                <span className="dotted dotted-7"></span>
                <span className="dotted dotted-8"></span>
                <span className="dotted dotted-9"></span>
                <span className="dotted dotted-10"></span>
              </div>
              <div className="container">
              <div className="content-box">
                  <h1> Membership Payment </h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/"> Home </Link></li>
                      <li> Membership Payment {this.state.membership}</li>
                  </ul>
              </div>
          </div>
        </div>
            
              <div className="login-1">
              <div className="container">
                 <div className="row">
                 <div className="col-md-1">
                        &nbsp;
              </div>
              <div className="col-md-10 logsss3">
                     <form onSubmit={this.changePassword} className="login-forms">
                            <h1> Payment Process</h1> 
                            <div className="clearfix"></div>
                            <hr/>
                            <div className="clearfix"></div>
                            {pricealllist.map((item, index) => (
                               <div key={item.price_id}>
                            
       <section className="pricing-section style-two">
      <div className="tabs-box">
         <div className="inner-content">
            <div className="tabs-content">
               <div className="tab active-tab" id="tab-1">
                  <div className="row clearfix">
                     <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
                        <div className="pricing-block-two">
                           <div className="pricing-table">
                              <div className="pattern-layer" ></div>
                              <div className="choice-box">
                                 <h6>Popular</h6>
                              </div>
                              <div className="table-header">
                                 <h4> Silver </h4>
                                 <h2>{item.Sliver}</h2>
                              </div>
                              
                              <div className="table-footer">
                              <div className="form-group col-md-12">
                                            <input type="radio" className="radio" id="awesome-item-1" value="Sliver" checked={this.state.packagePlan === "Sliver"} onChange={this.packageselected}   />
                                            <label className="radio__label" htmlFor="awesome-item-1"> <span >Active  </span></label>
                                          </div>
                                
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
                        <div className="pricing-block-two active_block">
                           <div className="pricing-table">
                              <div className="pattern-layer" ></div>
                              <div className="choice-box">
                                 <h6>Popular</h6>
                              </div>
                              <div className="table-header">
                                 <h4>Gold </h4>
                                 <h2>{item.Gold}</h2>
                              </div>
                              
                              <div className="table-footer">
                                     
                          
                                          <div className="form-group col-md-12">
                                             <input type="radio" className="radio" id="awesome-item-2" value="Gold" checked={this.state.packagePlan === "Gold"} onChange={this.packageselected} />
                                             <label className="radio__label" htmlFor="awesome-item-2"> <span >Active </span> </label>
                                          </div>
                                
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
                        <div className="pricing-block-two">
                           <div className="pricing-table">
                              <div className="pattern-layer" ></div>
                              <div className="choice-box">
                                 <h6>Popular</h6>
                              </div>
                              <div className="table-header">
                                 <h4>Platinum</h4>
                                 <h2>{item.Platinum}</h2>
                              </div>
                             
                              <div className="table-footer">
                             
                                          <div className="form-group col-md-12">
                                                 <input type="radio" className="radio" id="awesome-item-3" value="Platinum" checked={this.state.packagePlan === "Platinum"} onChange={this.packageselected}   />
                                                 <label className="radio__label" htmlFor="awesome-item-3"> <span >Active </span> </label>
                                          </div>
                               
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   
</section>

<div className="clearfix"></div>               
<h4 className="payment-titleqqq"> Onetime Fee: Paypal / debit and credit Card Payment </h4>
<p className="renewtext2"> <span style={{color:"#ff5722"}}> Note: </span> This is a one-time payment. There's NO automatic renewal when your membership ends, the payment has to be made again manually. </p>
<div className="clearfix"></div>
<div className="aaa5">
     <h4 className="payment-title">Choose your payment method</h4>
              
     <div className="col-md-1">
                        &nbsp;
              </div>
              <div className="col-md-10 ">
                    
                     <div className="clearfix"></div>
                     <div className="row-payment-method payment-row">
                     <div className="select-icon">
                            <input type="radio" id="radio2" name="radios" value="pp" defaultChecked onClick={this.stripeMode} />
                            <label htmlFor="radio2"></label>
                     </div>
                     <div className="select-txt">
                            <p className="pymt-type-name"> Stripe Credit / Debit Card </p>
                            <p className="pymt-type-desc">Safe payment online. Accept all major debit and credit cards from customers in every country.</p>
                     </div>
                     <div className="select-logo">
                            <img src={stripe} alt="stripe"/>
                     </div>
                     
                     </div>

                     <hr />
                     <div className="row-payment-method payment-row">
                     <div className="select-icon">
                            <input type="radio" id="radio1" name="radios" value="pp"  onClick={this.paypalMode}/>
                            <label htmlFor="radio1"></label>
                     </div>
                     <div className="select-txt">
                            <p className="pymt-type-name">Paypal</p>
                            <p className="pymt-type-desc">Safe payment online. Credit card needed. PayPal account is not necessary.</p>
                     </div>
                     <div className="select-logo">
                            <img src={paypal} alt="PayPal"/>
                     </div>
                     
                     </div>
                     <hr/>
             
                     <div className="clearfix"></div>

              {this.state.paynowstripe === true ? 
              <>
{/* // button 1 */}
              {this.state.packagePlan === 'Sliver' ? <> 
              <StripeCheckout stripeKey="pk_live_yn6RjQtCI6a8ITcRqjeSdLKv00FSgvPKl8" token={this.makePayment} name="Silver" description="Membership Onetime Fee" amount={item.Sliver * 100}  currency="USD" shippingAddress billingAddress >
                     <div className="theme-btn style-one"> Pay Now  </div>
                     </StripeCheckout>
                      </>: null}
{/* // button 2*/}
               {this.state.packagePlan === 'Gold' ?  <>
               <StripeCheckout stripeKey="pk_live_yn6RjQtCI6a8ITcRqjeSdLKv00FSgvPKl8" token={this.makePayment} name="Gold" description="Membership Onetime Fee" amount={item.Gold * 100}  currency="USD" shippingAddress billingAddress >
                     <div className="theme-btn style-one"> Pay Now   </div>
                     </StripeCheckout>
                      </>: null}
{/* // button 3 */}
               {this.state.packagePlan === 'Platinum' ? <> 
                <StripeCheckout stripeKey="pk_live_yn6RjQtCI6a8ITcRqjeSdLKv00FSgvPKl8" token={this.makePayment} name="Platinum " description="Membership Onetime Fee" amount={item.Platinum * 100}  currency="USD" shippingAddress billingAddress >
                     <div className="theme-btn style-one"> Pay Now  </div>
                     </StripeCheckout>
                      </>: null}
                    
            
             </>
             
              :
              <>

              {/* // button 1 */}
                             {this.state.packagePlan === 'Sliver' ? <> <a href={BaseURL+`paypal/pay/`+memberid+`/`+item.Sliver+`/`+this.state.packagePlan}  className="theme-btn style-one" > Pay Now </a> </>: null}
               {/* // button 2*/}
                            {this.state.packagePlan === 'Gold' ?  <> <a href={BaseURL+`paypal/pay/`+memberid+`/`+item.Gold+`/`+this.state.packagePlan}  className="theme-btn style-one" > Pay Now  </a> </>: null}
              {/* // button 3 */}
                            {this.state.packagePlan === 'Platinum' ? <><a href={BaseURL+`paypal/pay/`+memberid+`/`+item.Platinum+`/`+this.state.packagePlan}  className="theme-btn style-one" > Pay Now  </a>  </>: null}
                            
              
              
                              {/* <Link className="theme-btn style-one" to={`/paypal/pay/${memberid}/${this.state.packagePlan}`} > Pay Now {memberid} {this.state.packagePlan}  </Link> */}
                                    
                             </>
              }



</div>




    </div>

    </div>

  ))}
              </form>  
          </div>
          </div>
          </div>
          </div>
          </>
        );
    }
}
export default Payment;