import React from 'react';

import {AppContext} from '../Context';
class Logout extends React.Component {
    
    static contextType = AppContext;
    
    componentDidMount(){
        localStorage.clear();
        window.location.href = '/344bf263351937df9e4a804270ecb939';


    }



    render(){
        return(
             null
        );
    }
}
export default Logout;