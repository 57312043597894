import React from 'react';
import {Provider} from '../Context';
import Actions from '../Actions/Actions';
import {Redirect,Link } from "react-router-dom"; 
import { ToastContainer, toast} from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dashboard from '../Components/Dashboard';
import Dashboardads from '../Components/Dashboardads';
import DashboardBusiness from '../Components/DashboardBusiness';
import Profile from '../Components/Profile';
import SocialMedia from '../Components/SocialMedia';
import Changepassword from '../Components/Changepassword';
import Logout from '../Components/Logout';



let BaseURL = 'https://boescify.com/';

class Myaccount extends Actions { 
        
componentDidMount(){
    this.storedata();
    window.scrollTo(0, 0);
    this.setState({isactive: true })
 } 
           storedata() {
              let userstorageid = JSON.parse(localStorage.getItem('tockenboescifyid'));
              if (localStorage.getItem('tockenboescifyid')) {
                  this.setState({ boescifyuserid: userstorageid, })
                 
              } else { this.setState({ boescifyuserid: '',}) }
                 
             }
        
 Dashboard =()=>{
       this.setState({ 
        isDashboard: false,
        isClassified: false,
        isBusiness: false,
        isProfile: false,
        isPassword: false,
        isMedia: false,
        isLogOut: false,
        isadminpayment: false,
        isadmincoupen: false,
       });
     }
     Classified =()=>{
       this.setState({ 
        isDashboard: true,
        isClassified: true,
        isBusiness: false,
        isProfile: false,
        isPassword: false,
        isMedia: false,
        isLogOut: false,
        isadminpayment: false,
        isadmincoupen: false,
       });
     }
     Business =()=>{
       this.setState({ 
        isDashboard: true,
        isClassified: false,
        isBusiness: true,
        isProfile: false,
        isPassword: false,
        isMedia: false,
        isLogOut: false,
        isadminpayment: false,
        isadmincoupen: false,
       });
     }
     Profile =()=>{
       this.setState({ 
        isDashboard: true,
        isClassified: false,
        isBusiness: false,
        isProfile: true,
        isPassword: false,
        isMedia: false,
        isLogOut: false,
        isadminpayment: false,
        isadmincoupen: false,
       });
     }
     Password =()=>{
       this.setState({ 
        isDashboard: true,
        isClassified: false,
        isBusiness: false,
        isProfile: false,
        isPassword: true,
        isMedia: false,
        isLogOut: false,
        isadminpayment: false,
        isadmincoupen: false,
       });
     }
     Media =()=>{
       this.setState({ 
        isDashboard: true,
        isClassified: false,
        isBusiness: false,
        isProfile: false,
        isPassword: false,
        isMedia: true,
        isLogOut: false,
        isadminpayment: false,
        isadmincoupen: false,
       });
     }
     LogOut =()=>{
       this.setState({ 
        isDashboard: true,
        isClassified: false,
        isBusiness: false,
        isProfile: false,
        isPassword: false,
        isMedia: false,
        isLogOut: true,
        isadminpayment: false,
        isadmincoupen: false,
       });
     }
     adminpayment =()=>{
       this.setState({ 
        isDashboard: true,
        isClassified: false,
        isBusiness: false,
        isPassword: false,
        isMedia: false,
        isLogOut: false,
        isadminpayment: true,
        isadmincoupen: false,
       });
     }
     admincoupen =()=>{
       this.setState({ 
        isDashboard: true,
        isClassified: false,
        isPassword: false,
        isLogOut: false,
        isMedia: false,
        isadminpayment: false,
        isadmincoupen: true,
       });
     }
  render(){

       if (this.state.boescifyuserid === '' && this.state.isactive === true ) {
              return(
                <>
                   {toast("please login to access this page", {
                     position: "top-center" })}
                    <Redirect to='/344bf263351937df9e4a804270ecb939' />
                </>
      
              ) 
            }


    const contextValue = {
       fetchclassifieds:this.fetchclassifieds,
       all_dashboardsads:this.state.dashboardsads,

       fetchbusiness:this.fetchbusiness,
       all_dashboarddusiness:this.state.dashboarddusiness,

       changePassword:this.changePassword,


       fetchregister:this.fetchregister,
       all_active:this.state.Previewregister,

       profileupdate:this.profileupdate,

       mediaupdate:this.mediaupdate,

    }
 const dashboardsads = {
      name:  this.state.dasregistername,
      status:  this.state.dasregisterstatus,
      member: this.state.dasregistermember,
      profile_image: this.state.dasregisterprofile_image,
 }
  const dashboardbusiness = {
      name:  this.state.businessname,
      status:  this.state.businessstatus,
      member: this.state.businessmember,
      profile_image: this.state.businessprofile_image,

 }

    return (
      <Provider value={contextValue}>
              <div className="breadcrumbs">
              <div className="bubble-dotted">
                <span className="dotted dotted-1"></span>
                <span className="dotted dotted-2"></span>
                <span className="dotted dotted-3"></span>
                <span className="dotted dotted-4"></span>
                <span className="dotted dotted-5"></span>
                <span className="dotted dotted-6"></span>
                <span className="dotted dotted-7"></span>
                <span className="dotted dotted-8"></span>
                <span className="dotted dotted-9"></span>
                <span className="dotted dotted-10"></span>
              </div>
              <div className="container">
              <div className="content-box">
                  <h1> My Account
 </h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/"> Home </Link></li>
                      <li> My Account </li>
                  </ul>
              </div>
          </div>
        </div>

        
       <div className="members">
       <div className="container">
       <div className="row">
              <div className="col-md-3" style={{padding:0}}>
              <ul className="memberstabsss">
                     <li onClick={this.Dashboard} className={ this.state.isDashboard? "nav-item": "active" } >
                     <FontAwesomeIcon icon={['fas','th-large']} className="iconsss2" />
                     Dashboard 
                     </li>
                     <li onClick={this.Classified} className={ this.state.isClassified? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','money-check-alt']} className="iconsss2" />
                     Classified Ads

                     </li>
                     <li onClick={this.Business} className={ this.state.isBusiness ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','money-check-alt']} className="iconsss2" />
                     Business Directory </li>
                     <li onClick={this.Profile} className={ this.state.isProfile ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','user-cog']} className="iconsss2" />
                     Profile Settings </li> 
                     <li onClick={this.Password} className={ this.state.isPassword? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','user-edit']} className="iconsss2" />
                     Change Password
                     </li>
                     <li onClick={this.Media} className={ this.state.isMedia ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','share-alt']} className="iconsss2" />
                      Social Media
                     </li>
                     {this.state.userid === '3' ? 
                     <li onClick={this.adminpayment} className={ this.state.isadminpayment ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','user-edit']} className="iconsss2" />
                     Admin Payment
                     </li>
                     : null }
                     {this.state.userid === '3' ? 
                     <li onClick={this.admincoupen} className={ this.state.isadmincoupen ? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','user-edit']} className="iconsss2" />
                     Admin Coupen 
                     </li>
                     : null }
                     <li onClick={this.LogOut} className={ this.state.isLogOut? "active": "nav-item" }>
                     <FontAwesomeIcon icon={['fas','sign-out-alt']} className="iconsss2" />
                     Log Out
                     </li>
              </ul>

              </div>
              <div className="col-md-9">
              {this.state.isactive === true ?
                     <div className="card card-body">
                    
                            {this.state.isDashboard !== true ?
                                   <>
                                     <Dashboard  storageid={this.state.boescifyuserid} />
                                   </>
                                   : null } 
                            {this.state.isClassified === true ?
                                   <>
                                   <Dashboardads  dashboardsadslist={dashboardsads} storageid={this.state.boescifyuserid} />
                                   </>
                                   : null }
                            {this.state.isBusiness === true ?
                                   <>
                                   <DashboardBusiness  dashboardsadslist={dashboardbusiness} storageid={this.state.boescifyuserid} />
                                   </>
                                   : null }
                            {this.state.isProfile === true ?
                                   <>
                                   <Profile storageid={this.state.boescifyuserid} loadingchangepassword ={this.state.isLoading} />
                                   </>
                                   : null }
                            {this.state.isMedia === true ?
                                   <>
                                   <SocialMedia storageid={this.state.boescifyuserid} />
                                   </>
                                   : null }
                            {this.state.isPassword === true ?
                                   <Changepassword  storageid={this.state.boescifyuserid} loadingchangepassword ={this.state.isLoading}/>
                                   : null }
                                   {this.state.isLogOut === true ?
                                          <Logout /> 
                                   : null }

                     </div>
                     : null }
              </div>

       </div>
      </div>
      



       </div>
     
      </Provider>
    );
  }
}
export default Myaccount;