import React, { Component } from 'react';

import {AppContext} from '../Context';
import Loader from 'react-loader-spinner'
class Changepassword extends Component {
       
    static contextType = AppContext;
    
       changePassword = (event) => {
              this.context.changePassword(event,this.newpassword.value,this.confirmpassword.value,this.props.storageid);
       }


    render(){
       const {  loadingchangepassword} = this.props;
        return(
              <form onSubmit={this.changePassword} className="c-form">
                  
                            <h4 className="card-title2"> Change Password  </h4>
                            <div className="row">
                                   <div className="col-sm-12 col-xs-12">
                                        
                                          <div className="form-group">
                                                 <label className="font-weight-bold" htmlFor="exampleInputPassword11">Password <span className="adverts-form-required"> * </span></label>
                                                 <input type="password" ref={(val) => this.newpassword = val} className="form-control" id="exampleInputPassword11" placeholder="Password" />
                                          </div>
                                          <div className="form-group">
                                                 <label className="font-weight-bold" htmlFor="exampleInputPassword12">Confirm Password <span className="adverts-form-required"> * </span></label>
                                                 <input type="confirmpassword" ref={(val) => this.confirmpassword = val}className="form-control" id="exampleInputPassword12" placeholder="Confirm Password" />
                                          </div>
                                          <div className="form-group col-md-12 text-right">
                                          {loadingchangepassword ?
                                                 <>
                                                        <Loader type="ThreeDots" color="#00BFFF" height={77} width={77} style={{float: "left"}}/>
                                                 </>
                                                 : 
                                                 <button type="submit" className="theme-btn style-one"> Save </button>
                                          } 
                                          </div>
                                         
                                       
                                   </div>
                            </div>
                    
              
          </form>  
        );
    }
}
export default Changepassword;