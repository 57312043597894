import React from 'react';
import {BrowserRouter,Route,Switch} from 'react-router-dom';
import { ToastContainer} from "react-toastify";
import {NavLink, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import './App.css';

import Navbarmenu from './Components/Navbarmenu';
import Home from './Components/Home';
//import Members from './Components/Members';
import Loginsection from './Components/Loginsection';
import Resetpassword from './Components/Resetpassword';
import Payment from './Components/Payment';
import Success from './Components/Success';
import Cancel from './Components/Cancel';
import Contmail from './Components/Contmail';
import SuccessStripe from './Components/SuccessStripe';
import Myaccount from './Components/Myaccount';
import AddClassified from './Components/AddClassified';
import Viewads from './Components/Viewads';
import Addbusiness from './Components/Addbusiness';
import Viewbusiness from './Components/Viewbusiness';
import Paymentupdate from './Components/Paymentupdate';
import AllClassifieds from './Components/AllClassifieds';
import AllBusiness from './Components/AllBusiness';
import Logoutnew from './Components/Logoutnew';
import Searchs from './Components/Searchs';

import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas} from '@fortawesome/free-solid-svg-icons';
library.add(fas, fab)

function App() {
  return (
    <BrowserRouter basename="/">
      <ToastContainer />
        <div className="App">
           <div className="container">
              <div className="row align-items-center">
                <Navbarmenu/>
              </div>
            </div>
      
        
        
          <Switch>
          <Route exact path="/" component={Home}/>
          {/* <Route exact path="/ef53538ae41a651c7f72ab6cb1135d8c" component={Members}/> */}
          <Route exact path="/344bf263351937df9e4a804270ecb939" component={Loginsection}/>
          <Route exact path="/d8924631365a406ee72e3de9d01ce3af/:passkey" component={Resetpassword}/>
          <Route exact path="/c453a4b8e8d98e82f35b67f433e3b4da/:memberid" component={Payment}/>
          <Route exact path="/260ca9dd8a4577fc00b7bd5810298076/:successid" component={Success}/>
          <Route exact path="/10aec35353f9c4096a71c38654c3d402" component={Cancel}/>
          <Route exact path="/e5ed76de5cc54c37ff7adf73156002b7/:memberid" component={SuccessStripe}/>
          <Route exact path="/9d6adc5f7a6f7a9311bf8cfebad6f84f" component={Myaccount}/>
          <Route exact path="/16fe4ae43ecb50933e1c67d26612a11d" component={AddClassified}/>
          <Route exact path="/bb1bfd2adeaac7902e3634d1c349b4a4/:classifiedid" component={Viewads}/>
          <Route exact path="/722f967d25445b42c87118d0b2268c94" component={Addbusiness}/>
          <Route exact path="/d49a8c63af5cecc00f2d59c9f96b9e70/:businessid" component={Viewbusiness}/>
          <Route exact path="/aaaee1bc14675c64b11b786a6a3e3555/:memberid" component={Paymentupdate}/>
          <Route exact path="/2be40c5ac91664a28a684f0a910bdf8d" component={AllClassifieds}/>
          <Route exact path="/3d265bdef38fe54bca66b766afb4979a" component={AllBusiness}/>
          <Route exact path="/3f09c1e9e1de50a0f5bc3c3d5d0d81f0" component={Logoutnew}/>
          <Route exact path="/886c6fa8cc060551c2e505ef4e7d224a/" component={Contmail}/>
          <Route exact path="/618d5fead1c5aa971fcc18d89d5cb0c2/" component={Searchs}/>



          </Switch>
          <div id="Footer" className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div id="content_block_02">
                  <div className="content-box">
                      <div className="sec-title ">
                        <div  className="title"> <span className="chooseus"> <FontAwesomeIcon icon={['fas','user']} className="chooseus2" /> </span> <span> About Us </span>
                        </div>
                      </div>
                </div>
              </div>

              <p>Boescify is an International organization meant for business owners and entrepreneurs. We held business conferences online and offline. We have business owners, community groups, on Facebook and LinkedIn.  With Boescify you can connect with other entrepreneurs and global leaders easily. We help you with international business opportunities. Please join our Facebook and Linkedin groups.</p>
            </div>
            <div className="col-md-2">
            <div id="content_block_02">
                  <div className="content-box">
                      <div className="sec-title ">
                        <div className="title"> <span className="chooseus"> <FontAwesomeIcon icon={['fas','user']} className="chooseus2" /> </span> <span> Follow Us </span>
                        </div>
                      </div>
                </div>
              </div>
            
              <ul className="content-boxsss">
                <li> <a href="https://www.facebook.com/groups/businessownersmarketers" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={['fab','facebook-square']} className="chooseus3 chooseus3facebook" /><span>Facebook</span></a></li>
                <li><a href="https://twitter.com/Noblein" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={['fab','twitter-square']} className="chooseus3 chooseus3twitter" /><span>Twitter</span></a></li>
                <li><a href="https://www.linkedin.com/groups/3790878/" rel="noopener noreferrer" target="_blank"><FontAwesomeIcon icon={['fab','linkedin']} className="chooseus3 chooseus3linkedin" /><span>Linkedin</span></a></li>
              </ul>
            
           
            

            </div> 
            <div className="col-md-3">
            <div id="content_block_02">
                  <div className="content-box">
                      <div className="sec-title ">
                        <div className="title"> <span className="chooseus"> <FontAwesomeIcon icon={['fas','user']} className="chooseus2" /> </span> <span> Quick Links </span>
                        </div>
                      </div>
                </div>
              </div>
            
                <ul>
                <li className="menu-item"><NavLink activeClassName='is-active' to="/16fe4ae43ecb50933e1c67d26612a11d/"> <FontAwesomeIcon icon={['fas','caret-right']} className="" />  Post Classified Ads </NavLink></li>
                <li className="menu-item"><NavLink activeClassName='is-active' to="/722f967d25445b42c87118d0b2268c94/"> <FontAwesomeIcon icon={['fas','caret-right']} className="footsss" /> Add Business </NavLink></li>
                <li className="menu-item"><NavLink activeClassName='is-active' to="/9d6adc5f7a6f7a9311bf8cfebad6f84f/"> <FontAwesomeIcon icon={['fas','caret-right']} className="footsss" /> My Account </NavLink></li>
                <li className="menu-item"><NavLink activeClassName='is-active' to="/3d265bdef38fe54bca66b766afb4979a/"> <FontAwesomeIcon icon={['fas','caret-right']} className="footsss" /> Business Directory </NavLink></li>
                </ul>
            
           
            

            </div> 
           
            <div className="col-md-2">
            <div id="content_block_02">
                  <div className="content-box">
                      <div className="sec-title ">
                        <div className="title"> <span className="chooseus"> <FontAwesomeIcon icon={['fas','user']} className="chooseus2" /> </span> <span> Contact Us </span>
                        </div>
                      </div>
                </div>
              </div>
            <ul>
                    <li className="menu-item"><NavLink activeClassName='is-active' to="/886c6fa8cc060551c2e505ef4e7d224a/"> <FontAwesomeIcon icon={['fas','caret-right']} className="footsss" /> Contact Form </NavLink></li>
            </ul>
            </div>
          </div>
        </div>
      </div>
          <div className="copyrights">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p><span className="white">Copyright © 2021 <Link to={'/'}  className="green"> boescify </Link>. All Rights Reserved.</span></p>
            </div>
          </div>
        </div>
      </div>


       </div>
    </BrowserRouter>
  );
}

export default App;
