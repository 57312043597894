import React from 'react';
import Axios from 'axios';
import { toast } from "react-toastify";
import { HashLink as Link } from 'react-router-hash-link';
let BaseURL = 'https://boescify.com/';
//let BaseNodeURL = 'http://192.168.0.128:4000/';

class Actions extends React.Component{
    state = {
        isLoading: false,
        Previewregister:[],
        Previewregisteractive:[],
        isactive: false,
        dashboardsads:[],
        classifiedviewads:[],
        dashboarddusiness:[],
        businessviewads:[],
        Platinum:[],
        Gold:[],
        Sliver:[],
        Business:[],
        Pricemmm:[],
        
        
    }

   
// REGISTER
Register = (event,user_name,user_email,password,conformpassword,membership,recaptcha_response) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    event.persist();
    if (password !== conformpassword) {
        this.setState({ isLoading: false });
            toast.error('Password and Confirm Password Dismatched');
      }else { 
    Axios.post(BaseURL+'login/Register',{
        user_name:user_name,
        user_email:user_email,
        password:password,
        membership:membership,
        recaptcha_response:recaptcha_response,
    })
    .then(function ({data}) {
        if(data.success === 1){
            console.log(data);
            this.setState({ isLoading: false });
            toast('Create an account successfully');
            
            localStorage.setItem('tockenboescifyid',JSON.stringify(data.memberid));      
            event.target.reset();
            this.props.history.push(`/c453a4b8e8d98e82f35b67f433e3b4da/${data.memberid}`)
        }
        else{
            this.setState({ isLoading: false });
            toast.error(data.msg, {
                position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });
        }
    }.bind(this))
    .catch(function (error) {
        this.setState({ isLoading: false });
    });
}
}

//  USER LOGIN
userLogin = (event,email,password) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    event.persist();
    Axios.post(BaseURL+'login/userlogin',{
        email:email,
        password:password
    })
    .then(function ({data}) {
       
        if(data.success === 1){
            this.setState({ isLoading: false });
            localStorage.setItem('tockenboescifyid',JSON.stringify(data.users[0].register_id));         
            event.target.reset();
           toast.success('Welcome ' + data.users[0].name, {
            position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
          });   
          if(data.users[0].status === 'Active') {
            this.props.history.push('/9d6adc5f7a6f7a9311bf8cfebad6f84f');
          } else{  
            this.props.history.push(`/c453a4b8e8d98e82f35b67f433e3b4da/${data.users[0].register_id}`);
          }
        }
        else{
            toast.error(data.msg, {
                position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });
              this.setState({ isLoading: false });
        }
    }.bind(this))
    .catch(function (error) {
        this.setState({ isLoading: false });
    });
}
//  Forget Password
ForgetMail = (event,user_email) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    event.persist();
    Axios.post(BaseURL+'login/forgetpassword',{
        user_email:user_email,
    })
    .then(function ({data}) {
        console.log(data);
        if(data.success === 1){
           toast.success(data.msg,  {
            position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
          });
          event.target.reset();
          this.setState({ isLoading: false });
        }
        else{
            toast.error(data.msg, {
                position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });
              this.setState({ isLoading: false });
        }
    }.bind(this))
    .catch(function (error) { //console.log(error); 
        this.setState({ isLoading: false });
    });
}
//  Change Password
changePassword = (event,newpassword,confirmpassword,id) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    event.persist();
    if (newpassword !== confirmpassword) {
        this.setState({ isLoading: false });
            toast.error('Password and Confirm Password Dismatched');
      }else { 
    Axios.post(BaseURL+'login/changepassword',{
        newpassword:newpassword,
        id :id,
    })
    .then(function ({data}) {
        if(data.success === 1){
            this.setState({ isLoading: false });
            event.target.reset();
           toast.success('Change Password Successfully ', {
            position: "top-right",autoClose: 5000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
          });
        }
        else{
            toast.error(data.msg, {
                position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              });
              this.setState({ isLoading: false });
        }
    }.bind(this))
    .catch(function (error) {
        this.setState({ isLoading: false });
    });
    }
}
// BOOKS ADD
booksAdd = (event,storageid,title,file,description,website,category,descothers,phone,email,paln) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    event.persist();
    const formData = new FormData();
    formData.append('storageid',storageid)
    formData.append('avatar',file)
    formData.append('title',title)
    formData.append('description',description)
    formData.append('website',website)
    formData.append('category',category)
    formData.append('descothers',descothers)
    formData.append('phone',phone)
    formData.append('email',email)
    return Axios.post(BaseURL+'add/classifieds',formData,{
        headers: {'content-type': 'multipart/form-data' }
    })
    .then(res => { 
        if(res.data.success === 1){
            this.setState({ isLoading: false });
            event.target.reset();
            toast.info(({ onClose }) => (<Link to={`/#${paln}`} style={{color:"white"}}>
                <div>
                    <span>Add Post Successfully.View Your Ads</span> Click Here 
                </div>
                </Link>
              ),{
                position: "top-center",autoClose: false,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              })

          
        }  
        
    })
    .catch(err => {  toast.error(err.data); this.setState({ isLoading: false }); })   
}
// BOOKS ADD
businessAdd = (event,storageid,title,file,description,website,category,descothers,phone,email) => {
    this.setState({ isLoading: true });
    event.preventDefault();
    event.persist();
    const formData = new FormData();
    formData.append('storageid',storageid)
    formData.append('avatar',file)
    formData.append('title',title)
    formData.append('description',description)
    formData.append('website',website)
    formData.append('category',category)
    formData.append('descothers',descothers)
    formData.append('phone',phone)
    formData.append('email',email)
    return Axios.post(BaseURL+'add/business',formData,{
        headers: {'content-type': 'multipart/form-data' }
    })
    .then(res => { 
        if(res.data.success === 1){
            this.setState({ isLoading: false });
            event.target.reset();
            toast.info(({ onClose }) => (<Link to={`/#Business`} style={{color:"white"}}>
                <div>
                    <span>Add Post Successfully.View Your Ads</span> Click Here 
                </div>
                </Link>
              ),{
                position: "top-center",autoClose: false,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
              })

          
        }
        
    })
    .catch(err => {  toast.error(err.data); this.setState({ isLoading: false }); })   
}
fetchregister = (id) => { 
    Axios.post(BaseURL+'viewall/register',{ registerid:id,})
    .then(({data}) => {
        //console.log(data);
        if(data.success === 1){
            this.setState({ Previewregister:data.memberlist,registerpaln:data.memberlist[0].member,});
           
        }
    })
    .catch(error => { console.log(error);  })
}

activeregister = (id) => { 
    Axios.post(BaseURL+'viewall/registerActive',{ adduserid:id,})
    .then(({data}) => {
       // console.log(data);
        if(data.success === 1){
            this.setState({
                Previewregisteractive:data.memberlistactive,
                
            });
        }
    })
    .catch(error => { console.log(error);  })
}
fetchclassifieds = (id) => { 
    Axios.post(BaseURL+'viewall/classifieddashboard',{ adduserid:id,})
    .then(({data}) => {
       // console.log(data);
        if(data.success === 1){
            this.setState({
                dasregistername:data.memberlistactive[0].name,
                dasregisterstatus:data.memberlistactive[0].status,
                dasregistermember:data.memberlistactive[0].member,
                dasregisterprofile_image:data.memberlistactive[0].profile_image,
                dashboardsads:data.ads,
                
            });
        }
    })
    .catch(error => { console.log(error);  })
}
fetchbusiness = (id) => { 
    Axios.post(BaseURL+'viewall/businessdashboard',{ adduserid:id,})
    .then(({data}) => {
       // console.log(data);
        if(data.success === 1){
            this.setState({
                businessname:data.memberlistactive[0].name,
                businessstatus:data.memberlistactive[0].status,
                businessmember:data.memberlistactive[0].member,
                businessprofile_image:data.memberlistactive[0].profile_image,
                dashboarddusiness:data.ads,
                
            });
        }
    })
    .catch(error => { console.log(error);  })
}
classifiedsview = (id) => { 
    window.scrollTo(0, 0);
    Axios.post(BaseURL+'viewall/classifiedview',{ classifiedid:id,})
    .then(({data}) => {
       // console.log(data);
        if(data.success === 1){
            this.setState({
                classifiedviewstatus:data.memberlistactive[0].status,
                classifiedviewmember:data.memberlistactive[0].member,
                classifiedviewprofile_image:data.memberlistactive[0].profile_image,
                classifiedviewads:data.ads,
                
            });
        }
    })
    .catch(error => { console.log(error);  })
}
businessview = (id) => { 
    window.scrollTo(0, 0);
    Axios.post(BaseURL+'viewall/businessview',{ businessid:id,})
    .then(({data}) => {
       // console.log(data);
        if(data.success === 1){
            this.setState({
                businessviewstatus:data.memberlistactive[0].status,
                businessviewmember:data.memberlistactive[0].member,
                businessviewprofile_image:data.memberlistactive[0].profile_image,
                businessviewads:data.ads,
                
            });
        }
    })
    .catch(error => { console.log(error);  })
}
// ON EDIT MODE PREVIEW
editModepreview = (id) => {
    
  
    //editModepreview = (id,descriptionviews,paymentbook) => {
        let editads = this.state.classifiedviewads.map(adsview => {
            if(adsview.classified_id === id){      
                adsview.isEditing = true;
                return adsview;
            }
            adsview.isEditing = false;
            return adsview;      
        });
        this.setState({ editads});   
    }
//CANCEL EDIT MODE
cancelEdit = (id) => {
    window.scrollTo(0, 0);
    let cancelads = this.state.classifiedviewads.map(adsview => {
        if(adsview.classified_id === id){
            adsview.isEditing = false;
            return adsview;
        }
        return adsview       
    });
    this.setState({
        cancelads
    });
}// ON EDIT MODE PREVIEW
editModepreviewbusiness = (id) => {
    
  
    //editModepreview = (id,descriptionviews,paymentbook) => {
        let editads = this.state.businessviewads.map(adsview => {
            if(adsview.business_id === id){      
                adsview.isEditing = true;
                return adsview;
            }
            adsview.isEditing = false;
            return adsview;      
        });
        this.setState({ editads});   
    }
//CANCEL EDIT MODE
cancelEditbusiness = (id) => {
    window.scrollTo(0, 0);
    let cancelads = this.state.businessviewads.map(adsview => {
        if(adsview.business_id === id){
            adsview.isEditing = false;
            return adsview;
        }
        return adsview       
    });
    this.setState({
        cancelads
    });
}
    previewbookUpdate = (classifiedids,storageid,title,file,description,website,category,descothers,phone,email,fileempty) => {
        this.setState({ isLoading: true });
      
        const formData = new FormData();
        formData.append('classifiedids',classifiedids)
        formData.append('storageid',storageid)
        formData.append('avatar',file)
        formData.append('title',title)
        formData.append('description',description)
        formData.append('website',website)
        formData.append('category',category)
        formData.append('descothers',descothers)
        formData.append('phone',phone)
        formData.append('email',email)
        formData.append('fileempty',fileempty)
        return Axios.post(BaseURL+'update/classifiedsupdate',formData,{
            headers: {'content-type': 'multipart/form-data' }
        })
        .then(res => { 
            if(res.data.success === 1){
                toast.success('Edit Successfull')
                this.classifiedsview(res.data.classifiedids);
                let editads = this.state.classifiedviewads.map(adsview => {
                    if(adsview.classified_id === res.data){
                        adsview.isEditing = false;
                        return adsview;
                    }
                    adsview.isEditing = true;
                    return adsview;
                });
                this.setState({ editads,isLoading: false, fileempty:false, });
    
              
            }
            
        })
        .catch(err => {  toast.error(err.data); this.setState({ isLoading: false, fileempty:false, }); })   
    }
    previewbusinessUpdate = (businessids,storageid,title,file,description,website,category,descothers,phone,email,fileempty) => {
        this.setState({ isLoading: true });
      
        const formData = new FormData();
        formData.append('businessids',businessids)
        formData.append('storageid',storageid)
        formData.append('avatar',file)
        formData.append('title',title)
        formData.append('description',description)
        formData.append('website',website)
        formData.append('category',category)
        formData.append('descothers',descothers)
        formData.append('phone',phone)
        formData.append('email',email)
        formData.append('fileempty',fileempty)
        return Axios.post(BaseURL+'update/businessupdate',formData,{
            headers: {'content-type': 'multipart/form-data' }
        })
        .then(res => { 
            if(res.data.success === 1){
                toast.success('Edit Successfull')
                this.businessview(res.data.businessids);
                let editads = this.state.businessviewads.map(adsview => {
                    if(adsview.business_id === res.data){
                        adsview.isEditing = false;
                        return adsview;
                    }
                    adsview.isEditing = true;
                    return adsview;
                });
                this.setState({ editads,isLoading: false, fileempty:false, });
    
              
            }
            
        })
        .catch(err => {  toast.error(err.data); this.setState({ isLoading: false, fileempty:false, }); })   
    }

profileupdate = (storageid,file,names,gender,phone,about,fileempty) => {
        this.setState({ isLoading: true });
      
        const formData = new FormData();
        formData.append('storageid',storageid)
        formData.append('name',names)
        formData.append('avatar',file)
        formData.append('about',about)
        formData.append('phone',phone)
        formData.append('gender',gender)
        formData.append('fileempty',fileempty)
        return Axios.post(BaseURL+'update/profileupdate',formData,{
            headers: {'content-type': 'multipart/form-data' }
        })
        .then(res => { 
            if(res.data.success === 1){
                toast.success('Successfull')
                this.fetchregister(res.data.id);
                this.setState({ isLoading: false, fileempty:false, });
    
              
            }
            
        })
        .catch(err => {  toast.error(err.data); this.setState({ isLoading: false, fileempty:false, }); })   
    }

mediaupdate = (storageid,facebook,twitter,linkedin) => {
        this.setState({ isLoading: true });
        Axios.post(BaseURL+'update/SocialMedia',{
            storageid:storageid,
            facebook:facebook,
            twitter:twitter,
            linkedin:linkedin,
        })
        .then(function ({data}) {
            if(data.success === 1){
                toast.success('Successfull')
                this.fetchregister(data.id);
                this.setState({ isLoading: false, });
            }
        }.bind(this))
        .catch(function (error) {
            this.setState({ isLoading: false });
        });


    }

PlatinumClassifieds = () => { 
        Axios.get(BaseURL+'viewall/classifiedPlatinum')
        .then(({data}) => {
            if(data.success === 1){
                this.setState({
                    Platinum:data.Platinumall,                   
                });
            }
            this.GoldClassifieds();
        })
        .catch(error => { console.log(error);  })
    }
GoldClassifieds = () => { 
        Axios.get(BaseURL+'viewall/classifiedGold')
        .then(({data}) => {
            if(data.success === 1){
                this.setState({
                    Gold:data.Goldall,                   
                });
            }
            this.SliverClassifieds();
        })
        .catch(error => { console.log(error);  })
    }

SliverClassifieds = () => { 
        Axios.get(BaseURL+'viewall/classifiedSliver')
        .then(({data}) => {
            if(data.success === 1){
                this.setState({
                    Sliver:data.Sliverall,                   
                });
            }
            this.Businessallfronts();
        })
        .catch(error => { console.log(error);  })
    }
Businessallfronts = () => { 
        Axios.get(BaseURL+'viewall/businessallfronts')
        .then(({data}) => {
            if(data.success === 1){
                this.setState({
                    Business:data.Businessall,                   
                });
            }
            this.Priceallfronts();
        })
        .catch(error => { console.log(error);  })
    }

Priceallfronts = () => { 
        Axios.get(BaseURL+'viewall/price')
        .then(({data}) => {
            if(data.success === 1){
                this.setState({
                    Pricemmm:data.priceall,                   
                });
            }
        })
        .catch(error => { console.log(error);  })
    }








}

export default Actions;