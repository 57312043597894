import React from 'react';
import {Provider} from '../Context';
import Actions from '../Actions/Actions';
import Viewadstwo from '../Components/Viewadstwo';


import {Link } from "react-router-dom"; 


class Viewads extends Actions { 
        
componentDidMount(){
    this.storedata();
    window.scrollTo(0, 0);
 } 
           storedata() {
              let userstorageid = JSON.parse(localStorage.getItem('tockenboescifyid'));
              if (localStorage.getItem('tockenboescifyid')) {
                  this.setState({ boescifyuserid: userstorageid,isactive: true  })
              } else { this.setState({ boescifyuserid: '', isactive: false }) }
                 
             }
            
  render(){

    

    const contextValue = {
       classifiedsview:this.classifiedsview,
       all_classifiedviewads:this.state.classifiedviewads,
       
       editModepreview:this.editModepreview,
       
       cancelEdit:this.cancelEdit,

       fetchregister:this.fetchregister,
      all_active:this.state.Previewregister,

      previewbookUpdate:this.previewbookUpdate,

    }
    const dashboardsads = {
       status:  this.state.classifiedviewstatus,
       member: this.state.classifiedviewmember,
       profile_image: this.state.classifiedviewprofile_image,
 
  }

    return (
      <Provider value={contextValue}>
              <div className="breadcrumbs">
              <div className="bubble-dotted">
                <span className="dotted dotted-1"></span>
                <span className="dotted dotted-2"></span>
                <span className="dotted dotted-3"></span>
                <span className="dotted dotted-4"></span>
                <span className="dotted dotted-5"></span>
                <span className="dotted dotted-6"></span>
                <span className="dotted dotted-7"></span>
                <span className="dotted dotted-8"></span>
                <span className="dotted dotted-9"></span>
                <span className="dotted dotted-10"></span>
              </div>
              <div className="container">
              <div className="content-box">
                  <h1> Classified Ads</h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/"> Home </Link></li>
                      <li> Classified Ads </li>
                  </ul>
              </div>
          </div>
        </div>

        
       <div className="login-1">
       <div className="container">
       <div className="row">
       <div className="col-md-1">
                              &nbsp;

                       </div>
                            <div className="col-md-10 logsss3 ">
                           
        <Viewadstwo dashboardsadslist={dashboardsads} paramid={this.props.match.params.classifiedid} loadingedits ={this.state.isLoading} useridqqq={this.state.boescifyuserid}/>
       


       </div>
       </div>
       </div>
       </div>
     
      </Provider>
    );
  }
}
export default Viewads;