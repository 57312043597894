import React,{Component} from 'react';
import {AppContext} from '../Context';
import ContentLoader from "react-content-loader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'react-moment';
import Viewadstwoedit from '../Components/Viewadstwoedit';
let BaseURL = 'https://boescify.com/uploads/';

class Viewadstwo extends Component {
  static contextType = AppContext;
  constructor(props) {
       super(props);
       this.state = {
            isEditing:false,
         };
         
     }

        
componentDidMount(){
  this.classifiedsview();
 }
 classifiedsview = () => {
  this.context.classifiedsview(this.props.paramid);
}   


businessview = (url) => {
  if (!!url && !!url.trim()) { 
         url = url.trim(); 
         if (!/^(https?:)?\/\//i.test(url)) { 
             url = 'http://' + url; 
             window.open(url, '_blank');
         }else{
                window.open(url, '_blank');
         }
     } 
}  
          render(){
            let allUsers;
            let mainData;
            allUsers = this.context.all_classifiedviewads.map(({classified_id,class_member_id,title,image,description,website,category,other,phone,email,date,isEditing}) => {
              //const dateToFormat = date;
            return isEditing === true ? (
              <div key={classified_id}>
                   
                      <div className="form-row">
                        <Viewadstwoedit userids={this.props.paramid} classifiedids ={classified_id} desc={description} title={title} file={image} website={website} category={category} other={other} phone={phone} email={email}  loadingsssedits ={this.props.loadingedits}/>
                       
        {/* {this.props.desc} */}
        </div>

       </div>
       ) :  ( <div className="col-md-12" key={classified_id}>
              {this.props.useridqqq === class_member_id ? 
              <>
               <button className="theme-btn style-three editsss" onClick={() => this.context.editModepreview(classified_id)}>Edit</button>
               </>
               : null}
               <div className="col-md-6">
                     <div className="news-block-one ">
                     <div className="inner-box">
                            <figure className="image-box">
                                   
                            <img src={BaseURL+image} alt="" />
                         
                            </figure>
                            {/* <div className="lower-content">
                            <figure className="author-image"><img src={BaseURL+this.props.dashboardsadslist.profile_image} alt="" /></figure>
                            </div> */}
                     </div>
                     </div>
                     
                     <ul className="post-info clearfix">
                                   <li> <b> Category : </b>  {category === 'other' ?
                                          <>{other}</>
                                          :  <>{category}  </>}</li>
                                  

                                     
                                     {website !== '' ? 
                                        <li onClick={() => this.businessview(website)}> <b> Website : </b> <span style={{color: "#0082ff",cursor:"pointer"}}> {website} </span></li>
                                      : null}  
                                       {phone !== '' ?  
                                          <li> <b> Phone : </b> <a href={'tel:'+phone} rel="noopener noreferrer" target="_blank">{phone} </a>  </li>
                                          : null} 
                                       {email !== '' ?  
                                          <li> <b> Email : </b>  <a href={'mailto:'+email} rel="noopener noreferrer" target="_blank">{email} </a></li>
                                          : null}  
                                   
           
                            </ul>
                     </div>
                     <div className="col-md-6 awsewesss">
                            <h2> {title} </h2>
                            <p style={{marginBottom:"7px"}}> <b> Posted : </b> <Moment format="MMM D, YYYY" withTitle>
                                          {date} 
                                     </Moment> </p>
                            <div className="desc" dangerouslySetInnerHTML={{ __html: description }} />
                     </div>
                     </div>    
              )
            });
            if(this.context.all_classifiedviewads.length > 0){
              mainData = ( <div>{allUsers}  </div> );
          }else{
            mainData = ( <div>
              <ContentLoader height={337}width={1200}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > <rect x="8" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="8" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="76" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="18" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="166" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="127" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="187" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="18" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="8" y="317" rx="3" ry="3" width="37" height="11"/> <rect x="477" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="478" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="576" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="518" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="666" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="527" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="587" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="500" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="478" y="317" rx="3" ry="3" width="37" height="11"/>  </ContentLoader> 
              </div> );

          }
            
              return (
                  <>
                    <section className="gold-sectionview">

                
                     {mainData}

              </section>

                    
              </>  
             
              );
          }
      }

export default Viewadstwo;
