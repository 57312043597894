
import React from 'react';

import {Provider} from '../Context';
import Actions from '../Actions/Actions';
//include page
import Loginpage from '../Components/Loginpage';
import Forgetpassword from '../Components/Forgetpassword';
import Registerpage from '../Components/Registerpage';
import {Redirect,Link } from "react-router-dom"; 
import { ToastContainer, toast} from "react-toastify";


class Loginsection extends Actions{

  componentDidMount(){
    this.storedata();
    window.scrollTo(0, 0);
  }  
  
  storedata() {
    let userstorageid = JSON.parse(localStorage.getItem('tockenboescifyid'));
    if (localStorage.getItem('tockenboescifyid')) {
        this.setState({ boescifyuserid: userstorageid })} else { this.setState({ boescifyuserid: '', }) }   
   }

 RegisterMode = () => {
       window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({ 
          //isLogin: false,
          isLogin: true,
          isRegister: true,
          isForget: false, 
      
        });
      }
    
LoginMode = () => {
       window.scrollTo({top: 0, behavior: 'smooth'});
    this.setState({ 
      //isLogin: true,
      isLogin: false,
      isRegister: false,
      isForget: false, 
    });
  
  }
  ForgetMode = () => {
       window.scrollTo({top: 0, behavior: 'smooth'});
    this.setState({ 
      //isLogin: false,
      isLogin: true,
      isRegister: false,
      isForget: true, 
    });
  
  }  


    render(){

      if (this.state.boescifyuserid) {
        return(
          <>
             {toast("please logout to access this page", {
               position: "top-center" })}
             <Redirect to='/9d6adc5f7a6f7a9311bf8cfebad6f84f/' />
          </>

        ) 
      }
       
        const contextValue = {
            Register:this.Register,
            userLogin:this.userLogin,
            ForgetMail:this.ForgetMail,
           

         
        }
        return(
            <Provider value={contextValue}>
            <ToastContainer />
            <div className="breadcrumbs">
              <div className="bubble-dotted">
                <span className="dotted dotted-1"></span>
                <span className="dotted dotted-2"></span>
                <span className="dotted dotted-3"></span>
                <span className="dotted dotted-4"></span>
                <span className="dotted dotted-5"></span>
                <span className="dotted dotted-6"></span>
                <span className="dotted dotted-7"></span>
                <span className="dotted dotted-8"></span>
                <span className="dotted dotted-9"></span>
                <span className="dotted dotted-10"></span>
              </div>
              <div className="container">
              <div className="content-box">
                  <h1> Sign into your account  </h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/"> Home </Link></li>
                      <li>Login / Register</li>
                  </ul>
              </div>
          </div>
        </div>
               <div className="login-1">
                  <div className="container">
                     <div className="row">
                     
                       <div className="col-md-2">
                              &nbsp;

                       </div>
                       <div className="col-md-8 logsss3">
                          

                          {/* Login Mode */}
                          {this.state.isLogin !== true ?
                              <>
                              <Loginpage loadinglogin ={this.state.isLoading}/>
                                <h6 onClick={this.ForgetMode}> Forgot Password </h6>
                                <h6 onClick={this.RegisterMode}> Don't have an account? <span style={{color:'#fa6342'}}> Create an account </span>  </h6>
                             </>
                            : null }

                            {/* Register Mode */}
                              {this.state.isRegister === true ?
                                <>
                                <Registerpage loadingregister ={this.state.isLoading}/>
                                  <h6 onClick={this.LoginMode}> Already have an Account ? <span style={{color:'#fa6342'}}> Login here </span></h6>
                                  </>
                              : null }

                              {/* Forget Password */}
                              {this.state.isForget === true ? 
                              <>
                              <Forgetpassword loadingadsss ={this.state.isLoading} />  
                              <h6 onClick={this.RegisterMode}> Don't have an account? <span style={{color:'#fa6342'}}> Create an account </span>  </h6>
                              </>
                              : null  }



                       </div>

                     


                     </div>
                  </div>
            </div>
            </Provider>
        );
    }
}
export default Loginsection;