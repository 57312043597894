import React, { Component } from 'react';

import { Link } from "react-router-dom";

class Cancel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isactive: false,
    };
  }
  componentDidMount(){
    this.storedata();
    window.scrollTo(0, 0);
  }  
  storedata() {
    let userstorageid = JSON.parse(localStorage.getItem('tockenboescifyid'));
    if (localStorage.getItem('tockenboescifyid')) {
        this.setState({ boescifyuserid: userstorageid,isactive: true, })} else { this.setState({ boescifyuserid: '',isactive: false, }) }   
   }
  




  render(){
    
    return (
      <>
       <div className="breadcrumbs">
              <div className="bubble-dotted">
                <span className="dotted dotted-1"></span>
                <span className="dotted dotted-2"></span>
                <span className="dotted dotted-3"></span>
                <span className="dotted dotted-4"></span>
                <span className="dotted dotted-5"></span>
                <span className="dotted dotted-6"></span>
                <span className="dotted dotted-7"></span>
                <span className="dotted dotted-8"></span>
                <span className="dotted dotted-9"></span>
                <span className="dotted dotted-10"></span>
              </div>
              <div className="container">
              <div className="content-box">
                  <h1> Payment Receipt </h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/"> Home </Link></li>
                      <li>Payment Receipt</li>
                  </ul>
              </div>
          </div>
        </div>
      <div className="successs">
      <div className="container">
        <div className="row">
           <div className="col-md-12">
    <div className="container-fluid">
    <div className="col-md-1">
                        &nbsp;
              </div>
              <div className="col-md-10 logsss3">
             <h2>Your PayPal Transaction Canceled.</h2>
             {this.state.isactive === true ? 
             <Link to={`/9d6adc5f7a6f7a9311bf8cfebad6f84f`} className="theme-btn style-two" > Payment Again </Link>
             : null}
             </div>
              </div>
              </div>
              </div>
              </div>
              </div>
              </>
      
    );
  }
}

export default Cancel;