import React from 'react';

import {Link } from "react-router-dom"; 
class Logoutnew extends React.Component {
    
    
    componentDidMount(){
        localStorage.clear();
        window.location.href = '/344bf263351937df9e4a804270ecb939';


    }



    render(){
        return(
            <>
            <div className="breadcrumbs">
             <div className="bubble-dotted">
               <span className="dotted dotted-1"></span>
               <span className="dotted dotted-2"></span>
               <span className="dotted dotted-3"></span>
               <span className="dotted dotted-4"></span>
               <span className="dotted dotted-5"></span>
               <span className="dotted dotted-6"></span>
               <span className="dotted dotted-7"></span>
               <span className="dotted dotted-8"></span>
               <span className="dotted dotted-9"></span>
               <span className="dotted dotted-10"></span>
             </div>
             <div className="container">
             <div className="content-box">
                 <h1> Logout </h1>
                 <ul className="bread-crumb clearfix">
                     <li><Link to="/"> Home </Link></li>
                     <li> Logout </li>
                 </ul>
             </div>
         </div>
       </div>


           </>
        );
    }
}
export default Logoutnew;