import React from 'react';

import {Provider} from '../Context';
import Actions from '../Actions/Actions';

import {Redirect,Link } from "react-router-dom"; 
import { ToastContainer,toast} from "react-toastify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//include page
import AddBusinesstwo from '../Components/AddBusinesstwo';
class Addbusiness extends Actions {
  
       componentDidMount(){
          this.storedata();
          window.scrollTo(0, 0);
          
       }  
      
       
       storedata() {
              let userstorageid = JSON.parse(localStorage.getItem('tockenboescifyid'));
              if (localStorage.getItem('tockenboescifyid')) {
                  this.setState({ boescifyuserid: userstorageid,isactive: true })
                } else { this.setState({ boescifyuserid: '',isactive: false }) }   
             }
         


  render(){
   
    const contextValue = {
     businessAdd:this.businessAdd, //insert
      fetchregister:this.fetchregister,
      all_active:this.state.Previewregister,
      
  }
       if (this.state.boescifyuserid === '' && this.state.isactive === false ) {
              return(
                <>
                   {toast("please login to access this page", {
                     position: "top-center" })}
                    <Redirect to='/344bf263351937df9e4a804270ecb939' />
                </>
      
              ) 
            }
           
       
    return (
       <Provider value={contextValue}>
              <ToastContainer />
            <div className="breadcrumbs">
              <div className="bubble-dotted">
                <span className="dotted dotted-1"></span>
                <span className="dotted dotted-2"></span>
                <span className="dotted dotted-3"></span>
                <span className="dotted dotted-4"></span>
                <span className="dotted dotted-5"></span>
                <span className="dotted dotted-6"></span>
                <span className="dotted dotted-7"></span>
                <span className="dotted dotted-8"></span>
                <span className="dotted dotted-9"></span>
                <span className="dotted dotted-10"></span>
              </div>
              <div className="container">
              <div className="content-box">
                  <h1> Business Directory </h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/"> Home </Link></li>
                      <li> Business Directory </li>
                  </ul>
              </div>
          </div>
        </div>
        <div className="login-1">
                  <div className="container">
                     <div className="row">
                     
                       <div className="col-md-1">
                              &nbsp;

                       </div>
                            <div className="col-md-10 logsss3 bbb2">
                                   <div className="gold-section">
                                   <div className="sec-title text-center"><div className="title"><span className="gold2">  <FontAwesomeIcon icon={['fas','star']} className="gold" />  </span><span> Add Business </span></div></div>
                                 <div className="clearfix"></div>
                                 
                                 {this.state.boescifyuserid !=='' && this.state.isactive === true  ? 
                                   <AddBusinesstwo storageid={this.state.boescifyuserid} loadingadsss ={this.state.isLoading} />
                                 : null
                                 } 
                                   </div>
                            </div>

                            <div className="col-md-1">
                            &nbsp;
                            </div>
                            </div>
                     </div>
              </div>
       </Provider>       
      
    );
  }
}

export default Addbusiness;