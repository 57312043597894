import React from 'react';
import {Provider} from '../Context';
import Actions from '../Actions/Actions';
import Hometwo from '../Components/Hometwo';


import { HashLink as Link } from 'react-router-hash-link';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import slide from '../Img/delete/slide-1-1.png';
class Home extends Actions { 
  componentDidMount(){
    window.scrollTo(0, 0);
 }
  render(){
    const contextValue = {
      PlatinumClassifieds:this.PlatinumClassifieds,
            all_Platinum:this.state.Platinum,
            all_Golds:this.state.Gold,
            all_Sliver:this.state.Sliver,
            all_Business:this.state.Business,
            all_Price:this.state.Pricemmm,
    }

    return (
      <Provider value={contextValue}>
       <div className="banner">
       <div className="bg-layer"></div>
         <div className="container">
           <div className="row">
           <div className="col-md-6">
           <div className="bannsss">
             <span>Business Owners Entrepreneurs and Startups</span>
             <h1>BECOME A MEMBER <b> TODAY </b></h1>
             <p> Boescify is the only global network exclusively for entrepreneurs. </p>
             <p> Share Experiences, Build Meaningful Connections And Access Relevant Business Opportunities.</p>
             <p>Boescify helps leading entrepreneurs learn and grow through peer-to-peer learning, once-in-a-lifetime experiences, and connections to experts.</p>
             <div className="btn-box " >
             <Link to={`/#members`}  smooth className="theme-btn style-one"> <FontAwesomeIcon icon={['fas','user']} className="svgfa" /> Become a Member</Link>
             <Link to={`/886c6fa8cc060551c2e505ef4e7d224a`} className="theme-btn style-two"><FontAwesomeIcon icon={['fas','paper-plane']} className="svgfa" />Contact us</Link>
              </div>
             </div>
             </div>
             <div className="col-md-6">
             <figure className="banners-image"><img src={slide} alt="" /></figure>
            
            </div>
            </div>
         </div>
       </div>
       <div className="clearfix"></div>
       <Hometwo  />
        
      </Provider>
    );
  }
}
export default Home;