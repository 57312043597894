import React,{Component} from 'react';
import {AppContext} from '../Context';
import Loader from 'react-loader-spinner';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 
import Moment from 'react-moment';
import user_profile from '../Img/user_profile.jpg';

let BaseURL = 'https://boescify.com/uploads/';

class Dashboardads extends Component {
  static contextType = AppContext;
       constructor(props) {
              super(props);
              this.state = {
                };
             
            }

        
componentDidMount(){
  this.fetchclassifieds();
 }
fetchclassifieds = () => {
  this.context.fetchclassifieds(this.props.storageid);
}   


          render(){
            let allUsers;
            let mainData;
            allUsers = this.context.all_dashboardsads.map(({classified_id,title,image,description,website,category,other,phone,email,date}) => {
              //const dateToFormat = date;
            return ( 
                     <div className="col-md-6" key={classified_id}>
                     <div className="news-block-one ">
                     <div className="inner-box">
                            <figure className="image-box">
                                   
                            <img src={BaseURL+image} alt="" />
                            <div className="overlay-box">
                            <Link to={`/bb1bfd2adeaac7902e3634d1c349b4a4/${classified_id}`} className="link-btn"> <span><FontAwesomeIcon icon={['fas','link']} className="linksss" /></span></Link>
                            </div>
                            </figure>
                            <div className="lower-content">
                            <figure className="author-image">
                            {this.props.dashboardsadslist.profile_image !=='' ? 
                                   <img src={BaseURL+this.props.dashboardsadslist.profile_image} alt="" />
                                   :
                                   <img src={user_profile} alt="" />
                            }
                     </figure>
                            <ul className="post-info clearfix">
                                   <li>{category === 'other' ?
                                          <>{other}</>
                                          :  <>{category}  </>}</li>
                                   <li> <Moment format="MMM D, YYYY" withTitle>
                                          {date} 
                                     </Moment> </li>
                                   
           
                            </ul>
                           
                           
                                     
                            <h3> {title} </h3>
                            <div className="desc2" dangerouslySetInnerHTML={{ __html: description }} />
                            <div className="lower-box">
                                   <div className="admin"><span>by</span>&nbsp; {this.props.dashboardsadslist.name}</div>
                                   <div className="link-box"><Link to={`/bb1bfd2adeaac7902e3634d1c349b4a4/${classified_id}`}>+<span>
                                    View More </span></Link>
                                   </div>
                            </div>
                            </div>
                     </div>
                     </div>
                     </div>    
                     

              )
            });
            if(this.context.all_active.length > 0){
              mainData = ( <div className="col-md-12">{allUsers}  </div> );
          }
            
              return (
                  <>
                    <section id="Platinum" className="gold-section">

                     <div className="container">
                     <div className="row">
                     <div className="col-md-12">
                     <div className="sec-title text-center">
                     <div className="title"><span className="gold2"> <FontAwesomeIcon icon={['fas','star']} className="gold" /> </span><span> Classifieds  </span></div>

                     </div>
                     </div>
                    
                     {mainData}
                     

                     </div>
                     </div>

              </section>

                    
              </>  
             
              );
          }
      }

export default Dashboardads;
