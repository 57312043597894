import React, { Component } from 'react';

import Loader from 'react-loader-spinner';

import {Link } from "react-router-dom"; 
import { toast } from "react-toastify";
import Axios from 'axios';


let BaseURL = 'https://boescify.com/';

class Resetpassword extends Component {
       
       constructor(props, ...args) {
              super(props, ...args);
              this.state = {
                isLoading: false
              };
            }
      
       changePassword = (event) => {
              this.setState({ isLoading: true });
          event.preventDefault();event.persist();
          if (this.newpassword.value !== this.confirmpassword.value) {
              this.setState({ isLoading: false });
                  toast.error('Password and Confirm Password Dismatched');
            }else { 
          return Axios.post(BaseURL+'login/resetpassword',{
              newpassword:this.newpassword.value,
              passkeyids:this.props.match.params.passkey,
          })
          .then(function ({data}) {
                 console.log(data);
              if(data.success === 1){
                     toast.success(data.msg, {
                            position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                          });
                     this.setState({ isLoading: false });
                     event.target.reset();
                     this.props.history.push('/344bf263351937df9e4a804270ecb939')
                 }
                 else{
                     this.setState({ isLoading: false });
                     toast.error(data.msg, {
                         position: "top-center",autoClose: 7000,hideProgressBar: false, closeOnClick: true,pauseOnHover: true,draggable: true,
                       });
                     }
          }.bind(this))
          .catch(function (error) {
            console.log(error);
            this.setState({ isLoading: false });
        });     
       }         
                }



    render(){
        return(
              <>
               <div className="breadcrumbs">
              <div className="bubble-dotted">
                <span className="dotted dotted-1"></span>
                <span className="dotted dotted-2"></span>
                <span className="dotted dotted-3"></span>
                <span className="dotted dotted-4"></span>
                <span className="dotted dotted-5"></span>
                <span className="dotted dotted-6"></span>
                <span className="dotted dotted-7"></span>
                <span className="dotted dotted-8"></span>
                <span className="dotted dotted-9"></span>
                <span className="dotted dotted-10"></span>
              </div>
              <div className="container">
              <div className="content-box">
                  <h1> Reset Password </h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/"> Home </Link></li>
                      <li> Reset Password </li>
                  </ul>
              </div>
          </div>
        </div>
            
              <div className="login-1">
              <div className="container">
                 <div className="row">
                 <div className="col-md-2">
                        &nbsp;
              </div>
              <div className="col-md-8 logsss3">
                     <form onSubmit={this.changePassword} className="login-forms">
                            <h1> Change Password </h1>
              
                            <div className="form-row">
                                   <div className="form-group col-md-12">
                                   <label> New Password </label>
                                   <input type="password" name="newpassword" ref={(val) => this.newpassword = val} className="form-control" placeholder=" New Password " required />
                                   </div>
                                   <div className="form-group col-md-12">
                                   <label> Confirm Password </label>
                                   <input type="password" name="confirmpassword" ref={(val) => this.confirmpassword = val} className="form-control" placeholder=" Confirm Password " required />
                                   </div>


                            
                                   <div className="form-group col-md-12 text-right">
                                   {this.state.isLoading ?
                                          <>
                                                 <Loader type="ThreeDots" color="#ef0f7e" height={67} width={67} style={{float: "left"}}/>
                                          </>
                                          : 
                                          <button type="submit" className="theme-btn style-two"> Submit </button>
                                   } 
                                   </div>
                            </div>

                     
                     
              </form>  
          </div>
          </div>
          </div>
          </div>
          </>
        );
    }
}
export default Resetpassword;