// this component for about page
import React, { Component } from 'react';
import {AppContext} from '../Context';
import ReCAPTCHA from "react-google-recaptcha";
import Loader from 'react-loader-spinner'
const TEST_SITE_KEY = "6Lcezb4UAAAAAM5-2BW7c_CIn-82wBjyFtB047l7";
const DELAY = 1500;

class Registerpage extends Component{
   static contextType = AppContext;
   constructor(props, ...args) {
    super(props, ...args);
    this.state = {
      callback: "not fired",
      value: "[empty]",
      load: false,
      expired: "false",
      recaptchaLoaded: false,
      
     
    };
    this._reCaptchaRef = React.createRef();
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ load: true });
    }, DELAY);
    console.log("didMount - reCaptcha Ref-", this._reCaptchaRef);
  }
  handleChange = value => {
    this.setState({ value });
   
  };
  asyncScriptOnLoad = () => {
    this.setState({ recaptchaLoaded: true });
    this._reCaptchaRef.current.execute();
  };
  Register = (event) => {
        this.context.Register(event,this.username.value,this.useremail.value,this.password.value,this.conformpassword.value,this.membership.value,this.recaptcha_response.value);
    }

    

    render(){
     
        const { value, load, recaptchaLoaded } = this.state || {};
        const {  loadingregister} = this.props;
        return(
            <form onSubmit={this.Register} className="login-forms">
                <h1> Create an account </h1>
                <div className="clearfix"></div>
            <div className="form-row">
            
                <div className="form-group col-md-12">
                    <label className="font-weight-bold">Full Name</label>
                    <input type="text" name="username" ref={(val) => this.username = val} className="form-control" placeholder="Your name here..." required   />
                   
                </div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bold">Email Address</label>
                    <input type="email" name="useremail" ref={(val) => this.useremail = val} className="form-control" placeholder="Your email here..." required />
                </div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bold"> Password </label>
                    <input type="password" name="password" ref={(val) => this.password = val} className="form-control" placeholder="Your password here..."  required />
                    
                </div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bold"> Confirm Password </label>
                    <input type="password" name="conformpassword" ref={(val) => this.conformpassword = val} className="form-control" placeholder="Your password here..." required />
                </div>
                <div className="form-group col-md-12">
                    <label className="font-weight-bold"> Membership Type  </label>
                    <select  name="membership" ref={(val) => this.membership = val}  className="wqsss form-control" required>
                                  
                                  <option value=""> Choose the membership type </option>
                                  <option value="Sliver">Silver</option>
                                  <option value="Gold">Gold</option>
                                  <option value="Platinum">Platinum</option>
                                </select>
                </div>

                <input type="hidden" name="recaptcha_response" ref={(val) => this.recaptcha_response = val} className="form-control" placeholder="Your password here..." required  defaultValue={value} />

                {load && (
          <ReCAPTCHA
            style={{ display: "inline-block" }}
            theme="dark"
            size="invisible"
            ref={this._reCaptchaRef}
            sitekey={TEST_SITE_KEY}
            onChange={this.handleChange}
            asyncScriptOnLoad={this.asyncScriptOnLoad}
          />
        )}


                <div className="form-group col-md-12 text-right">
                {loadingregister ?
                            <>
                                   <Loader type="ThreeDots" color="#ef0f7e" height={67} width={67} style={{float: "left"}}/>
                            </>
                         : 
                    <button type="submit" className="theme-btn style-two" style={{
                      marginBottom: "37px"}} disabled={!recaptchaLoaded}> Register Now </button>
                }
                </div>
            </div>
           
        </form>  
        );
    }
}
export default Registerpage;