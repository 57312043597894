import React, { Component } from 'react';

import {AppContext} from '../Context';
import Loader from 'react-loader-spinner';

class Profile extends Component {
       
    static contextType = AppContext;
   

    componentDidMount(){
       this.fetchregister();
      }
      fetchregister = () => {
       this.context.fetchregister(this.props.storageid);
     }   

       mediaupdate = (storageid) => {
              this.context.mediaupdate(storageid,this.facebook.value,this.twitter.value,this.linkedin.value);
       }


    render(){
       const {  loadingchangepassword} = this.props;
       let allUsers;
            let mainData;
            allUsers = this.context.all_active.map(({register_id,facebook,twitter,linkedin}) => {
             
            return ( 
                <div key={register_id}>
               <div className="row">
                            
               <div className="col-md-9">
                                        
                     <div className="form-group col-md-12">
                            <label className="font-weight-bold"> Facebook URL (optional) </label>
                            <input type="text" name="facebook" ref={(val) => this.facebook = val} className="form-control"  placeholder="Facebook URL" defaultValue={facebook} />
                      </div> 
                      <div className="form-group col-md-12">
                            <label className="font-weight-bold"> Twitter URL (optional) </label>
                            <input type="text" name="twitter" ref={(val) => this.twitter = val} className="form-control"  placeholder=" Twitter URL" defaultValue={twitter} />
                      </div> 
                     
                      <div className="form-group col-md-12">
                            <label className="font-weight-bold"> Linkedin URL (optional) </label>
                            <input type="text" name="linkedin" ref={(val) => this.linkedin = val} className="form-control"  placeholder="Linkedin URL" defaultValue={linkedin} />
                      </div>
                         
                                     
                      <div className="form-group col-md-12 text-right">
                      {loadingchangepassword ?
                       <>
                            <Loader type="ThreeDots" color="#00BFFF" height={77} width={77} style={{float: "left"}}/>
                     </>
                      : 
                      <button type="submit" className="theme-btn style-one" onClick={() => this.mediaupdate(this.props.storageid)}> Save </button>
                                                
                                          } 
                     </div>
                                         
                                       
               </div>
               </div>
                           
            


                    
              </div>
              )
            });
            if(this.context.all_active.length > 0){
              mainData = ( <div>{allUsers}  </div> );
          }
            
        return(
              <>
                     {mainData}
               
          </>  
        );
    }
}
export default Profile;