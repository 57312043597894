import React, { Component } from 'react';
import { AppContext } from '../Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 
import Moment from 'react-moment';
import ContentLoader from "react-content-loader";

import leaf1 from '../Img/leaf-1.png';
import leaf2 from '../Img/leaf-2.png';

import user_profile from '../Img/user_profile.jpg';

let BaseURLuploads = 'https://boescify.com/uploads/';

class Hometwo extends Component {
  static contextType = AppContext;
  componentDidMount() {
    this.context.PlatinumClassifieds();
  }
  render() {
    return (
      <> 
        {/* --------------------- Platinum ----------------------------------------------- */}
<section id="Platinum" className="gold-section">

   <div className="container">
   <div className="row">
   <div className="col-md-12">
      <div className="sec-title text-center">
      <div className="title"><span className="gold2"> <FontAwesomeIcon icon={['fas','star']} className="gold" /> </span><span> Premium </span></div>
      </div>
      </div>
      <div className="clearfix"></div>
      {this.context.all_Platinum.length > 0 ?
        <>
            {this.context.all_Platinum.map((item, index) => (
            <div className="col-md-4" key={item.classified_id}>
            <div className="news-block-one ">
                <div className="inner-box">
                    <figure className="image-box">
                        <img src={BaseURLuploads+item.image} alt="Classifiedsads" />
                        <div className="overlay-box">
                        <Link to={`/bb1bfd2adeaac7902e3634d1c349b4a4/${item.classified_id}`} className="link-btn"><span><FontAwesomeIcon icon={['fas','link']} className="linksss" /></span></Link>
                        </div>
                    </figure>
                    <div className="lower-content">
                    <figure className="author-image">
                            {item.profile_image !=='' ? 
                                <img src={BaseURLuploads+item.profile_image} alt="" />
                                :
                                <img src={user_profile} alt="" />
                            }
                            </figure>
                        <ul className="post-info clearfix">
                        <li>{item.category === 'other' ?
                            <>{item.other}</>
                            :  <>{item.category}  </>}</li>
                        <li> <Moment format="MMM D, YYYY" withTitle>
                                {item.date} 
                            </Moment> </li>
                        </ul>
                        <h3> {item.title} </h3>
                        <div className="lower-box">
                            <div className="admin"><span>by</span>&nbsp; {item.name}</div>
                            <div className="link-box"><Link to={`/bb1bfd2adeaac7902e3634d1c349b4a4/${item.classified_id}`}>+<span>
                                    View More </span></Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
        ))}
    </>
    : 
        <ContentLoader height={337}width={1200}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > <rect x="8" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="8" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="76" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="18" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="166" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="127" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="187" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="18" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="8" y="317" rx="3" ry="3" width="37" height="11"/> <rect x="400" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="408" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="476" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="418" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="566" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="527" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="587" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="418" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="408" y="317" rx="3" ry="3" width="37" height="11"/> <rect x="790" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="790" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="876" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="818" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="966" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="927" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="987" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="818" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="808" y="317" rx="3" ry="3" width="37" height="11"/> </ContentLoader> 
    }
  <div className="clearfix"></div>
   </div>
   </div>

</section>
 {/* -------------------------------------------------------------------------------------------- */}
  {/* --------------------- price table  ----------------------- */}
<section id="members" className="pricing-section style-two">
       <div className="image-layer">   
            <figure className="image image-1"><img src={leaf1} alt="" /></figure>
            <figure className="image image-2"><img src={leaf2} alt="" /></figure>
        </div>
   <div className="container">
      <div className="sec-title text-center">
         <div className="title-two">
            <p><span></span>&nbsp;Pricing Table&nbsp;<span></span></p>
         </div>
         <h3 style={{marginBottom:"0px"}}>We have offered the best price for Your Business</h3>
         <p className="renewtext"> <span style={{color:"#ff5722"}}> Note: </span> This is a one-time payment. There's NO automatic renewal when your membership ends, the payment has to be made again manually. </p>
      </div>
      {this.context.all_Price.map((item, index) => (
         
      <div className="tabs-box" Key={item.price_id}>
         <div className="inner-content">
            <div className="tabs-content">
               <div className="tab active-tab" id="tab-1">
                  <div className="row clearfix">
                     <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
                        <div className="pricing-block-two">
                           <div className="pricing-table">
                              <div className="pattern-layer" ></div>
                              <div className="choice-box">
                                 <h6>Popular </h6>
                              </div>
                              <div className="table-header">
                                 <h4> Silver </h4>
                                 <h2>{item.Sliver}</h2>
                              </div>
                              <div className="table-content">
                                 <ul>
                                 
                                    <li><span><FontAwesomeIcon icon={['fas','check']} className="searchiconsss" /></span>1.5 Years Membership</li>
                                    <li><span><FontAwesomeIcon icon={['fas','check']} className="searchiconsss" /></span>Onetime Payment </li>
                                   
                                 </ul>
                              </div>
                              <div className="table-footer">
                              <Link to={`/9d6adc5f7a6f7a9311bf8cfebad6f84f/`} > Get Started </Link>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
                        <div className="pricing-block-two active_block">
                           <div className="pricing-table">
                              <div className="pattern-layer" ></div>
                              <div className="choice-box">
                                 <h6>Popular</h6>
                              </div>
                              <div className="table-header">
                                 <h4>Gold </h4>
                                 <h2>{item.Gold}</h2>
                              </div>
                              <div className="table-content">
                                 <ul>
                                    <li><span><FontAwesomeIcon icon={['fas','check']} className="searchiconsss" /></span> 5 Years Membership </li>
                                    <li><span><FontAwesomeIcon icon={['fas','check']} className="searchiconsss" /></span> Onetime Payment </li>
                                 
                                 </ul>
                              </div>
                              <div className="table-footer">
                              <Link to={`/9d6adc5f7a6f7a9311bf8cfebad6f84f/`} > Get Started </Link>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="col-lg-4 col-md-6 col-sm-12 pricing-block">
                        <div className="pricing-block-two">
                           <div className="pricing-table">
                              <div className="pattern-layer" ></div>
                              <div className="choice-box">
                                 <h6>Popular</h6>
                              </div>
                              <div className="table-header">
                                 <h4>Platinum</h4>
                                 <h2>{item.Platinum}</h2>
                              </div>
                              <div className="table-content">
                                 <ul>
                                    <li><span><FontAwesomeIcon icon={['fas','check']} className="searchiconsss" /></span> Lifetime Membership </li>
                                    <li><span><FontAwesomeIcon icon={['fas','check']} className="searchiconsss" /></span> Onetime Payment </li>
                                   
                                 </ul>
                              </div>
                              <div className="table-footer">
                              <Link to={`/9d6adc5f7a6f7a9311bf8cfebad6f84f/`} > Get Started </Link>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      ))}
   </div>
</section>
 {/* -------------------------------------------------------------------------------------------- */}
 <section id="services" className="featured-section style-two bg-color-1s">
        <div className="bubble-dotted">
            <span className="dotted dotted-1"></span>
            <span className="dotted dotted-2"></span>
            <span className="dotted dotted-3"></span>
            <span className="dotted dotted-4"></span>
            <span className="dotted dotted-5"></span>
            <span className="dotted dotted-6"></span>
            <span className="dotted dotted-7"></span>
            <span className="dotted dotted-8"></span>
            <span className="dotted dotted-9"></span>
            <span className="dotted dotted-10"></span>
        </div>
        <div className="container">
        <div className="row clearfix">
        <div className=" col-md-12">
            <div className="sec-title centred">
                <div className="title-two"><p><span></span>&nbsp;Fetaured service&nbsp;<span></span></p></div>
                <h3>Additional Services that will<br />Grow Your Business</h3>
                <p>Some easy steps to boost &Grow Your Business</p>
                <br />
                <br />
            </div>
          </div>
        </div>
       </div>
            <div className="clearfix"></div>
                
        <div className="container">
        <div className="row clearfix">
      
                       
                            <div className=" col-md-3 feature-block">
                                <div className="feature-block-one">
                                    <div className="hover-content"></div>
                                    <div className="inner-box">
                                        <div className="icon-box">
                                            <span className="shadow-box"></span><span><FontAwesomeIcon icon={['fas','cog']} className="featuredonsss" /></span>
                                        </div>
                                        <h4>Pay-Per-Click</h4>
                                        <div className="text">
                                            <p>Google Ad campaigns are an effective way to target receptive audience they get you.</p>
                                        </div>
                                        <div className="link"><a href="contact.html" rel="noopener noreferrer">Contact us<span className="shadow-box"></span><span><FontAwesomeIcon icon={['fas','arrow-right']} className="featuredonsss" /></span></a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                                <div className="feature-block-one">
                                    <div className="hover-content"></div>
                                    <div className="inner-box">
                                        <div className="icon-box">
                                            <span className="shadow-box"></span>
                                            <span className="shadow-box"></span><span><FontAwesomeIcon icon={['fas','chart-bar']} className="featuredonsss" /></span>
                                        </div>
                                        <h4>Branding</h4>
                                        <div className="text">
                                            <p>Make sure your brand assets contribute to your company’s personality and reputation.</p>
                                        </div>
                                        <div className="link"><a href="contact.html" rel="noopener noreferrer">Contact us<span className="shadow-box"></span><span><FontAwesomeIcon icon={['fas','arrow-right']} className="featuredonsss" /></span></a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                                <div className="feature-block-one">
                                    <div className="hover-content"></div>
                                    <div className="inner-box">
                                        <div className="icon-box">
                                            <span className="shadow-box"></span>
                                            <span className="shadow-box"></span><span><FontAwesomeIcon icon={['fas','mars-double']} className="featuredonsss" /></span>
                                        </div>
                                        <h4>Content Marketing</h4>
                                        <div className="text">
                                            <p>Your content is an integral part of your SEO efforts and online marketing strategy.</p>
                                        </div>
                                        <div className="link"><a href="contact.html" rel="noopener noreferrer">Contact us<span className="shadow-box"></span><span><FontAwesomeIcon icon={['fas','arrow-right']} className="featuredonsss" /></span></a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 feature-block">
                                <div className="feature-block-one">
                                    <div className="hover-content"></div>
                                    <div className="inner-box">
                                        <div className="icon-box">
                                            <span className="shadow-box"></span>
                                            <span className="shadow-box"></span><span><FontAwesomeIcon icon={['fas','envelope-open-text']} className="featuredonsss" /></span>
                                        </div>
                                        <h4>Email Marketing</h4>
                                        <div className="text">
                                            <p>Drive sales, build and support a wide range of initiatives through a strategic email.</p>
                                        </div>
                                        <div className="link"><a href="contact.html" rel="noopener noreferrer">Contact us<span className="shadow-box"></span><span><FontAwesomeIcon icon={['fas','arrow-right']} className="featuredonsss" /></span></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                    
               
        </div>
    </section>
 {/* -------------------------------------------------------------------------------------------- */}
       {/* --------------------- Gold ----------------------------------------------- */}
<section id="Gold" className="gold-section aaa">
      
      <div className="container">
      <div className="row">
      <div className="col-md-12">
         <div className="sec-title text-center">
         <div className="title"><span className="gold2"> <FontAwesomeIcon icon={['fas','gem']} className="gold" /> </span><span> Gold </span></div>
          
         </div>
         </div>
         <div className="clearfix"></div>
       
  
         {this.context.all_Golds.length > 0 ?
        <>
            {this.context.all_Golds.map((item, index) => (   
                <div className="add-block col-md-6" key={item.classified_id}>
                <div className="inner-box">
                    <div className="clearfix">   
                        <div className="image-column col-lg-6 col-md-6 col-sm-12">
                            <div className="inner-column">
                            <div className="image">
                                <div className="sale"> {item.category === 'other' ?
                                                <>{item.other}</>
                                                :  <>{item.category}  </>} 
                                    </div>
                                    <img src={BaseURLuploads+item.image} alt="Classifiedsads" />
                            </div>
                            </div>
                        </div>
                        
                        <div className="content-column col-md-6 ">
                            <div className="inner-column">
                            <ul className="post-meta clearfix">
                                <li><span><FontAwesomeIcon icon={['fas','clock']} className="gold" /> </span> <Moment format="MMM D, YYYY" withTitle>
                                   {item.date} 
                              </Moment></li>
                                
                            </ul>
                            <h4> {item.title} </h4>
                            
                            <div className="add-info clearfix">
                                <div className="pull-left">
                                <div className="desc2" dangerouslySetInnerHTML={{ __html: item.description }} />
                                <div className="lower-box">
                                    <div className="link-box"><Link to={`/bb1bfd2adeaac7902e3634d1c349b4a4/${item.classified_id}`}><span>
                             View More </span></Link></div></div>
                                </div>
                               
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            ))}
</>
: 
    <ContentLoader height={337}width={1200}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > <rect x="8" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="8" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="76" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="18" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="166" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="127" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="187" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="18" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="8" y="317" rx="3" ry="3" width="37" height="11"/> <rect x="400" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="408" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="476" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="418" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="566" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="527" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="587" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="418" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="408" y="317" rx="3" ry="3" width="37" height="11"/> <rect x="790" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="790" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="876" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="818" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="966" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="927" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="987" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="818" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="808" y="317" rx="3" ry="3" width="37" height="11"/> </ContentLoader> 
}
        <div className="clearfix"></div>
      </div>
      </div>
   </section>
    {/* -------------------------------------------------------------------------------------------- */}
    
    <section className="fun-facts">
        <div className="map-shap" ></div>
        <div className="container">
            <div className="sec-title centred ">
                <div className="title"><span className="chooseus"> <FontAwesomeIcon icon={['fas','star']} className="chooseus2" /> </span><span>Fun Facts</span></div>
                <h3>We have achieved some great<br /><span>Funfacts for experiences</span></h3>
                <p>We conduct deep research before we start any project for our clients.<br />Our state of art SEO Audit Software help us to run.</p>
            </div>
            <div className="row clearfix">
                <div className="col-lg-3 col-md-6 col-sm-12 block-column">
                    <div className="counter-block-one">
                        <div className="counter-block ">
                            <div className="count-outer count-box counted">
                                <span className="count-text" data-speed="1500" data-stop="24">24</span><span>k</span>
                            </div>
                            <h3><span>Happy</span>Clients</h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 block-column">
                    <div className="counter-block-one">
                        <div className="counter-block ">
                            <div className="count-outer count-box counted">
                                <span className="count-text" data-speed="1500" data-stop="36">36</span><span>+</span>
                            </div>
                            <h3><span>Expert</span>Team</h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 block-column">
                    <div className="counter-block-one">
                        <div className="counter-block ">
                            <div className="count-outer count-box counted">
                                <span className="count-text" data-speed="1500" data-stop="15">15</span><span>+</span>
                            </div>
                            <h3><span>Awards</span>Winner</h3>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-12 block-column">
                    <div className="counter-block-one">
                        <div className="counter-block ">
                            <div className="count-outer count-box counted">
                                <span className="count-text" data-speed="1500" data-stop="110">110</span><span>+</span>
                            </div>
                            <h3><span>Positive</span>Reviews</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    
    {/* -------------------------------------------------------------------------------------------- */}
       {/* --------------------- Platinum ----------------------------------------------- */}
<section id="Sliver" className="gold-section aaa">
      
      <div className="container">
      <div className="row">
      <div className="col-md-12">
         <div className="sec-title text-center">
         <div className="title"><span className="gold2"> <FontAwesomeIcon icon={['fas','gem']} className="gold" /> </span><span> Silver </span></div>
         
         </div>
         </div>
      
       <div className="clearfix"></div>

       {this.context.all_Sliver.length > 0 ?
        <>
            {this.context.all_Sliver.map((item, index) => (        
       <div className="col-md-6" key={item.classified_id}>
            <div className="news-block-one ">
                <div className="inner-box">
            <div className="col-md-5" style={{padding:0}}>
                        <figure className="image-box">
                        <img src={BaseURLuploads+item.image} alt="" />
                        <div className="overlay-box">
                        <Link to={`/bb1bfd2adeaac7902e3634d1c349b4a4/${item.classified_id}`} className="link-btn"><span><FontAwesomeIcon icon={['fas','link']} className="linksss" /></span></Link>
                        </div>
                        </figure>
                        <figure className="author-image">
                            {item.profile_image !=='' ? 
                            <img src={BaseURLuploads+item.profile_image} alt="" />
                            :
                            <img src={user_profile} alt="" />
                        }
                        
                        </figure>
                        <div className="lower-box2">
                   <div className="admin"><span>by</span>&nbsp; {item.name} </div>
                  
                   </div>
                    </div>
               
            <div className="col-md-7" style={{padding:0}}>
                
                    <div className="lower-content">
                    <h3> {item.title} </h3>
                   <ul className="post-info clearfix">
                   <li>{item.category === 'other' ?
                        <>{item.other}</>
                        :  <>{item.category}  </>}</li>
                    <li> <Moment format="MMM D, YYYY" withTitle>
                         {item.date} 
                        </Moment> 
                    </li>

                   </ul>
                   
                   <div className="hhhsss"> 
                   <div className="" dangerouslySetInnerHTML={{ __html: item.description }} />
                   </div>
                   <div className="lower-box">
                       <div className="admin">&nbsp;</div>
                       <div className="link-box3"> <Link to={`/bb1bfd2adeaac7902e3634d1c349b4a4/${item.classified_id}`} className=""><span> View More </span></Link></div>
                   </div>
               </div>
                    </div>
            </div>
          </div>
         </div> 

))}
</>
: 
    <ContentLoader height={337}width={1200}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > <rect x="8" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="8" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="76" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="18" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="166" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="127" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="187" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="18" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="8" y="317" rx="3" ry="3" width="37" height="11"/> <rect x="400" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="408" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="476" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="418" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="566" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="527" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="587" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="418" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="408" y="317" rx="3" ry="3" width="37" height="11"/> <rect x="790" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="790" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="876" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="818" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="966" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="927" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="987" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="818" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="808" y="317" rx="3" ry="3" width="37" height="11"/> </ContentLoader> 
}
 <div className="clearfix"></div>
      </div>
      </div>
   </section>
    {/* -------------------------------------------------------------------------------------------- */}

<section id="Business" className="fun-factsbussiness">
        <div className="container">
            <div className="sec-title centred ">
                <div className="title"><span className="chooseus"> <FontAwesomeIcon icon={['fas','star']} className="chooseus2" /> </span><span> Company Profiles </span></div>
                <h3>Business Directory</h3>
                
            </div>
            <div className="row clearfix">
{this.context.all_Business.length > 0 ?
 <>
     {this.context.all_Business.map((item, index) => (                 
            <div className="col-md-4" key={item.business_id}>
            {/* backgroundImage: `url(${Background})` */}
                <div className="city-card spring-fever" style={{backgroundImage:"url(" + BaseURLuploads+item.image + ")"}}>
                 
                        <div className="title-content">
                            <h3> {item.title} </h3>
                            <hr />
                        </div>
                        <div className="card-info"> 
                        <div className="mnsqqq" dangerouslySetInnerHTML={{ __html: item.description }} />
                        <div className="btn-box " >
                        <Link to={`/d49a8c63af5cecc00f2d59c9f96b9e70/${item.business_id}`} className="theme-btn style-one"><span>
                        <FontAwesomeIcon icon={['fas','user']} className="svgfa" /> View More </span></Link>
              </div>
              </div>
                        
                        <div className="utility-info">
                            <ul className="utility-list">
                          
                            <li className="comments">{item.category === 'other' ?
                                   <>{item.other}</>
                                   :  <>{item.category}  </>}</li> |
                            <li className="comments"> <Moment format="MMM D, YYYY" withTitle>
                                   {item.date} 
                              </Moment> </li>
                            </ul>
                        </div>
                        <div className="gradient-overlay"></div>
                        <div className="color-overlay"></div>
                   
                </div>
                </div>

))}
</>
: 
    <ContentLoader height={337}width={1200}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > <rect x="8" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="8" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="76" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="18" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="166" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="127" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="187" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="18" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="8" y="317" rx="3" ry="3" width="37" height="11"/> <rect x="400" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="408" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="476" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="418" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="566" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="527" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="587" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="418" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="408" y="317" rx="3" ry="3" width="37" height="11"/> <rect x="790" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="790" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="876" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="818" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="966" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="927" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="987" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="818" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="808" y="317" rx="3" ry="3" width="37" height="11"/> </ContentLoader> 
}


<div className="clearfix"></div>

            </div>
        </div>
</section>

      </>
    );
  }
}
export default Hometwo;