import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HashLink as Link } from 'react-router-hash-link';
import logo from '../Img/logo.png';
class Navbarmenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMenu: false
    };
    this.toggleClass = this.toggleClass.bind(this);
  }
  toggleClass() {
    this.setState({isMenu: !this.state.isMenu});
    this.storedata();
};
componentDidMount(){
  this.storedata();
} 
storedata() {
  let userstorageid = JSON.parse(localStorage.getItem('tockenboescifyid'));
  if (localStorage.getItem('tockenboescifyid')) {
      this.setState({ boescifyuserid: userstorageid,isactive: true })
     
  } else { this.setState({ boescifyuserid: '', isactive: false}) }
     
 }
    render(){
      let boxClass = ["main-menu menu-right menuq1"];
      if(this.state.isMenu) {
        boxClass.push('menuq2');
      }
        return(
          <header className="header-middle">
            <div className="container">
              <div className="row align-items-center">
                <div className="outer-box">
                  <div className="col-md-3" style={{padding:0}}>
                    <div className=" logo">
                    <NavLink exact activeClassName='is-active' to="/">
                    <img src={logo} alt="logo" />
                    {/* <img src={logo} alt="logo" style={{ width: '35px' }} /> */}
                    </NavLink>
                    <div className="book-shadow"></div>
                    </div>
                  </div>
                  <div className=" col-md-9" style={{padding:0}}>
                    <nav className="navBar ">
                    <span className="res5c" style={{ display: 'none' }} onClick={this.toggleClass} >  <FontAwesomeIcon icon={['fas','bars']} />  </span>
                        <ul className={boxClass.join(' ')}>
                            <li className="menu-item" onClick={this.toggleClass}><NavLink exact activeClassName='is-active' to="/">Home</NavLink></li>
                            <li className="menu-item" onClick={this.toggleClass}><NavLink activeClassName='is-active' to="/2be40c5ac91664a28a684f0a910bdf8d"> Classified Ads  </NavLink></li>
                            <li className="menu-item" onClick={this.toggleClass}><NavLink className="aaa2q" activeClassName='is-active'  to="/3d265bdef38fe54bca66b766afb4979a"> Business Directory </NavLink></li>
                            {/* <li className="menu-item" onClick={this.toggleClass}><Link smooth to="/#services"> Services </Link></li> */}
                           <li className="menu-item" onClick={this.toggleClass}><Link to="/#Footer" > About Us </Link></li>
                           
                            <li className="menu-item" onClick={this.toggleClass}><NavLink activeClassName='is-active' to="/9d6adc5f7a6f7a9311bf8cfebad6f84f"> My Account </NavLink></li> 
                            {this.state.boescifyuserid !== '' && this.state.isactive === true ? 
                                   <li className="menu-item" onClick={this.toggleClass}><NavLink activeClassName='is-active' to="/3f09c1e9e1de50a0f5bc3c3d5d0d81f0"> Logout </NavLink></li>
                                      : 
                                      <li className="menu-item" onClick={this.toggleClass}><NavLink activeClassName='is-active' to="/344bf263351937df9e4a804270ecb939"> Login / Register </NavLink></li>
                                  }
                            <li className="menu-item" onClick={this.toggleClass}><NavLink activeClassName='is-active' to="/618d5fead1c5aa971fcc18d89d5cb0c2"> <FontAwesomeIcon icon={['fas','search']} className="search" /> </NavLink></li> 
                           
                            
                        </ul>
                    </nav>
                  </div>
                  </div>
                </div>
            </div>
            </header>
        );
    }
}
export default Navbarmenu;