import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 
import Moment from 'react-moment';
import ContentLoader from "react-content-loader";
import user_profile from '../Img/user_profile.jpg';
import search from '../Img/search.png';

let BaseURL = 'https://boescify.com/';

let BaseURLuploads = 'https://boescify.com/uploads/';

class Searchs extends Component {
  constructor(props, ...args) {
    super(props, ...args);
    this.state = {
       adsall:[],   
       adsclassifiedsall:[],   
       adsbusinessall:[],   
       isClassifieds:false,
       isBusiness:false,
       searchValue: '',

    };
  }
  componentDidMount(){
    window.scrollTo(0, 0);
    this.allmakeApiCall();
  }  
 
  allmakeApiCall = () => {
       var searchUrl = (BaseURL+`viewall/searchAll`);
       fetch(searchUrl)
       .then(response => {
       return response.json();
       })
       .then(jsonData => {
       this.setState({ adsall: jsonData.allads });
       });
       };

    makeApiCall = searchInput => {
       var searchUrl = (BaseURL+`viewall/searchclassifieds?searchfilters=${searchInput}`);
       fetch(searchUrl)
       .then(response => {
       return response.json();
       })
       .then(jsonData => {
       this.setState({ adsclassifiedsall: jsonData.classifiedsallads });
       });
       };
   
     makeApiCallBusiness = searchInput => {
       var searchUrl =  (BaseURL+`viewall/searchbusiness?searchfilters=${searchInput}`);
       fetch(searchUrl)
       .then(response => { return response.json();})
       .then(jsonData => { this.setState({ adsbusinessall: jsonData.businessallads });  });
       };
   
    
   
   
     handleSearch = () => {
       if(this.state.isClassifieds === false && this.state.searchValue !== null ){ 
         this.makeApiCall(this.state.searchValue);
       }
       else if(this.state.isClassifieds === true){ 
         this.makeApiCallBusiness(this.state.searchValue);
       }
       
       };
   
     handleOnChange = (ValueHolder ) => {
      
       this.setState({ searchValue: ValueHolder.target.value, viewlists: true });
   
   
       if(this.state.isClassifieds === false && this.state.searchValue !== null ){ 
         this.makeApiCall(this.state.searchValue);
       }
       else if(this.state.isClassifieds === true){ 
         this.makeApiCallBusiness(this.state.searchValue);
       }
      
   
   
       };
   
   
   
   
   
       Classifieds =()=>{
         this.setState({ 
          isClassifieds: false,
          isBusiness: false,
         });
         this.makeApiCall(this.state.searchValue);
       }
       Business =()=>{
         this.setState({ 
          isBusiness: true,
          isClassifieds: true,
         });
         this.makeApiCallBusiness(this.state.searchValue);
       }
    
   
   
   
   
   
  render(){
       let  allUsers,mainData;
       if (this.state.adsall !== null) {
              allUsers = this.state.adsall.map(ads => (
                      <div className="col-md-4" key={ads.classified_id}>
                      <div className="news-block-one ">
                      <div className="inner-box">
                             <figure className="image-box">
                                    
                             <img src={BaseURLuploads+ads.image} alt="" />
                             <div className="overlay-box">
                             <Link to={`/bb1bfd2adeaac7902e3634d1c349b4a4/${ads.classified_id}`} className="link-btn"><span><FontAwesomeIcon icon={['fas','link']} className="linksss" /></span></Link>
                             </div>
                             </figure>
                             <div className="lower-content">
                             <figure className="author-image">
                               {ads.profile_image !=='' ? 
                                  <img src={BaseURLuploads+ads.profile_image} alt="" />
                                  :
                                  <img src={user_profile} alt="" />
                               }
                               </figure>
                             <ul className="post-info clearfix">
                                    <li>{ads.category === 'other' ?
                                           <>{ads.other}</>
                                           :  <>{ads.category}  </>}</li>
                                    <li> <Moment format="MMM D, YYYY" withTitle>
                                           {ads.date} 
                                      </Moment> </li>
                                    
            
                             </ul>
                            
                            
                                      
                             <h3> {ads.title} </h3>
                             <div className="desc2" dangerouslySetInnerHTML={{ __html: ads.description }} />
                             <div className="lower-box">
                                    <div className="admin"><span>by</span>&nbsp;{ads.name}</div>
                                    <div className="link-box"><Link to={`/bb1bfd2adeaac7902e3634d1c349b4a4/${ads.classified_id}`}>+<span>
                                     View More </span></Link>
                                    </div>
                             </div>
                             </div>
                      </div>
                      </div>
                      </div>  
               
              ));
              if( this.state.adsall.length > 0){
                mainData = ( <div>{allUsers}  </div> );
            } else{
              mainData = ( <div>
               <ContentLoader height={337}width={1200}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > <rect x="8" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="8" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="76" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="18" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="166" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="127" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="187" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="18" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="8" y="317" rx="3" ry="3" width="37" height="11"/> <rect x="400" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="408" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="476" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="418" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="566" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="527" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="587" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="418" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="408" y="317" rx="3" ry="3" width="37" height="11"/> <rect x="790" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="790" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="876" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="818" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="966" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="927" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="987" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="818" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="808" y="317" rx="3" ry="3" width="37" height="11"/> </ContentLoader> 
               </div> );
        
              
            }
            }
    return (
      <>
       <div className="breadcrumbs">
              <div className="bubble-dotted">
                <span className="dotted dotted-1"></span>
                <span className="dotted dotted-2"></span>
                <span className="dotted dotted-3"></span>
                <span className="dotted dotted-4"></span>
                <span className="dotted dotted-5"></span>
                <span className="dotted dotted-6"></span>
                <span className="dotted dotted-7"></span>
                <span className="dotted dotted-8"></span>
                <span className="dotted dotted-9"></span>
                <span className="dotted dotted-10"></span>
              </div>
              <div className="container">
              <div className="content-box">
                   <div className="searchbar">
               <input name="text"  type="text" placeholder=" Search Classified Ads, Business Directory
 ..."  onChange={ValueHolder  => this.handleOnChange(ValueHolder )} value={this.state.searchValue}
                />
                  <button onClick={this.handleSearch} className="btnmmm"> <img src={search} alt="search" />  </button>

            </div>
           


              </div>
          </div>
        </div>
      <div className="successs2">
          <div className="container">
            <div className="row">
               <div className="col-md-12">
        <div className="container-fluid">
     
              <div className="col-md-12 ">
              {this.state.viewlists === true ?
                <>
                     <div className="col-md-12">
                                   <ul className="nav-tabssss">
                                   <li onClick={this.Classifieds} className={ this.state.isClassifieds? "theme-btn style-two": "theme-btn style-three active" } > Classified Ads </li>
                                   <li onClick={this.Business} className={ this.state.isBusiness? "theme-btn style-three active": "theme-btn style-one" }> Business Directory </li>
                                   
                                   </ul>
              {this.state.isClassifieds !== true ?
              <>
                     { this.state.adsclassifiedsall.map(ads => ( 
                            <div className="col-md-4" key={ads.classified_id}>
                            <div className="news-block-one ">
                            <div className="inner-box">
                                   <figure className="image-box">
                                          
                                   <img src={BaseURLuploads+ads.image} alt="" />
                                   <div className="overlay-box">
                                   <Link to={`/bb1bfd2adeaac7902e3634d1c349b4a4/${ads.classified_id}`} className="link-btn"><span><FontAwesomeIcon icon={['fas','link']} className="linksss" /></span></Link>
                                   </div>
                                   </figure>
                                   <div className="lower-content">
                                   <figure className="author-image">
                                     {ads.profile_image !=='' ? 
                                        <img src={BaseURLuploads+ads.profile_image} alt="" />
                                        :
                                        <img src={user_profile} alt="" />
                                     }
                                     </figure>
                                   <ul className="post-info clearfix">
                                          <li>{ads.category === 'other' ?
                                                 <>{ads.other}</>
                                                 :  <>{ads.category}  </>}</li>
                                          <li> <Moment format="MMM D, YYYY" withTitle>
                                                 {ads.date} 
                                            </Moment> </li>
                                   </ul>
                                  
                                   <h3> {ads.title} </h3>
                                   <div className="desc2" dangerouslySetInnerHTML={{ __html: ads.description }} />
                                   <div className="lower-box">
                                          <div className="admin"><span>by</span>&nbsp;{ads.name}</div>
                                          <div className="link-box"><Link to={`/bb1bfd2adeaac7902e3634d1c349b4a4/${ads.classified_id}`}>+<span>
                                           View More </span></Link>
                                          </div>
                                   </div>
                                   </div>
                            </div>
                            </div>
                            </div>  
                     ))}
              </> : null }

              {this.state.isBusiness === true ?
                 <>
                 { this.state.adsbusinessall.map(ads => ( 
                        <div className="col-md-4" key={ads.business_id}>
                        <div className="news-block-one ">
                        <div className="inner-box">
                               <figure className="image-box">
                                      
                               <img src={BaseURLuploads+ads.image} alt="" />
                               <div className="overlay-box">
                               <Link to={`/d49a8c63af5cecc00f2d59c9f96b9e70/${ads.business_id}`} className="link-btn"><span><FontAwesomeIcon icon={['fas','link']} className="linksss" /></span></Link>
                               </div>
                               </figure>
                               <div className="lower-content">
                               <figure className="author-image">
                                 {ads.profile_image !=='' ? 
                                    <img src={BaseURLuploads+ads.profile_image} alt="" />
                                    :
                                    <img src={user_profile} alt="" />
                                 }
                                 </figure>
                               <ul className="post-info clearfix">
                                      <li>{ads.category === 'other' ?
                                             <>{ads.other}</>
                                             :  <>{ads.category}  </>}</li>
                                      <li> <Moment format="MMM D, YYYY" withTitle>
                                             {ads.date} 
                                        </Moment> </li>
                               </ul>
                              
                               <h3> {ads.title} </h3>
                               <div className="desc2" dangerouslySetInnerHTML={{ __html: ads.description }} />
                               <div className="lower-box">
                                      <div className="admin"><span>by</span>&nbsp;{ads.name}</div>
                                      <div className="link-box"><Link to={`/d49a8c63af5cecc00f2d59c9f96b9e70/${ads.business_id}`}>+<span>
                                       View More </span></Link>
                                      </div>
                               </div>
                               </div>
                        </div>
                        </div>
                        </div>  
                 ))}
          </> : null }

                     </div>
             </>
             : 
             <>
             {mainData}
             </>
  }

             

                     
             
              </div>
              </div>
              </div>
              </div>
              </div>
              </div>
      </>
    );
  }
}

export default Searchs;