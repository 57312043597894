import React, { Component } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link } from "react-router-dom"; 
import Moment from 'react-moment';
import ContentLoader from "react-content-loader";
import user_profile from '../Img/user_profile.jpg';

let BaseURL = 'https://boescify.com/';
let BaseURLuploads = 'https://boescify.com/uploads/';
class AllClassifieds extends Component {
  
  state = {
    classifieds: [],
    total: null,
    per_page: null,
    current_page: 1,
  }

  

  componentDidMount() {
    this.makeHttpRequestWithPage(1);
   // window.scrollTo(0, 0);
  }


  makeHttpRequestWithPage = async pageNumber => {
    
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    const response = await fetch(BaseURL+`viewall/Allclassifieds/${pageNumber}`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    });

    const res = await response.json();
    console.log(res);
    this.setState({
       classifieds: res.data,
       total: res.total,
       per_page: res.per_page,
       current_page: res.page,
    });
  }

  render() {
 
    let  allUsers,mainData, renderPageNumbers;
    if (this.state.classifieds !== null) {
      allUsers = this.state.classifieds.map(ads => (
              <div className="col-md-4" key={ads.classified_id}>
              <div className="news-block-one ">
              <div className="inner-box">
                     <figure className="image-box">
                            
                     <img src={BaseURLuploads+ads.image} alt="" />
                     <div className="overlay-box">
                     <Link to={`/bb1bfd2adeaac7902e3634d1c349b4a4/${ads.classified_id}`} className="link-btn"><span><FontAwesomeIcon icon={['fas','link']} className="linksss" /></span></Link>
                     </div>
                     </figure>
                     <div className="lower-content">
                     <figure className="author-image">
                       {ads.profile_image !=='' ? 
                          <img src={BaseURLuploads+ads.profile_image} alt="" />
                          :
                          <img src={user_profile} alt="" />
                       }
                       </figure>
                     <ul className="post-info clearfix">
                            <li>{ads.category === 'other' ?
                                   <>{ads.other}</>
                                   :  <>{ads.category}  </>}</li>
                            <li> <Moment format="MMM D, YYYY" withTitle>
                                   {ads.date} 
                              </Moment> </li>
                            
    
                     </ul>
                    
                    
                              
                     <h3> {ads.title} </h3>
                     <div className="desc2" dangerouslySetInnerHTML={{ __html: ads.description }} />
                     <div className="lower-box">
                            <div className="admin"><span>by</span>&nbsp;{ads.name}</div>
                            <div className="link-box"><Link to={`/bb1bfd2adeaac7902e3634d1c349b4a4/${ads.classified_id}`}>+<span>
                             View More </span></Link>
                            </div>
                     </div>
                     </div>
              </div>
              </div>
              </div>  
       
      ));
      if( this.state.classifieds.length > 0){
        mainData = ( <div>{allUsers}  </div> );
    } else{
      mainData = ( <div>
       <ContentLoader height={337}width={1200}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > <rect x="8" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="8" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="76" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="18" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="166" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="127" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="187" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="18" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="8" y="317" rx="3" ry="3" width="37" height="11"/> <rect x="400" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="408" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="476" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="418" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="566" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="527" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="587" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="418" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="408" y="317" rx="3" ry="3" width="37" height="11"/> <rect x="790" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="790" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="876" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="818" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="966" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="927" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="987" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="818" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="808" y="317" rx="3" ry="3" width="37" height="11"/> </ContentLoader> 
       </div> );

      
    }
    }else{
      return (
        <div className="" style={{padding:'60px'}}>
        <div className="container">
           <div className="row">
           <div className="col-md-12">
        <div className="alert alert-light" role="alert">
        <h4 className="alert-heading"> Please Wait </h4>
        <hr/>
        <ContentLoader height={337}width={1200}speed={2}primarycolor="#f3f3f3" secondarycolor="#ecebeb" > <rect x="8" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="8" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="76" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="18" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="166" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="127" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="187" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="18" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="8" y="317" rx="3" ry="3" width="37" height="11"/> <rect x="400" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="408" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="476" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="418" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="566" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="527" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="587" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="418" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="408" y="317" rx="3" ry="3" width="37" height="11"/> <rect x="790" y="0" rx="0" ry="0" width="357" height="233"/> <rect x="790" y="257" rx="3" ry="3" width="67" height="11"/> <rect x="876" y="257" rx="3" ry="3" width="140" height="11"/> <rect x="818" y="277" rx="3" ry="3" width="140" height="11"/> <rect x="966" y="277" rx="3" ry="3" width="173" height="11"/> <rect x="927" y="300" rx="3" ry="3" width="53" height="11"/> <rect x="987" y="300" rx="3" ry="3" width="72" height="11"/> <rect x="818" y="300" rx="3" ry="3" width="100" height="11"/> <rect x="808" y="317" rx="3" ry="3" width="37" height="11"/> </ContentLoader> 
    </div>
    </div>
    </div>
    </div>
    </div>
      )
     }

    const pageNumbers = [];
    if (this.state.total !== null) {
      for (let i = 1; i <= Math.ceil(this.state.total / this.state.per_page); i++) {
        pageNumbers.push(i);
      }


      renderPageNumbers = pageNumbers.map(number => {
        //console.log('current_page: '+this.state.page+' number : '+number);
        let classes = this.state.current_page != number ? '' : 'active';

        return (
          <span key={number} className={classes} onClick={() => this.makeHttpRequestWithPage(number)}> {number}</span>
        );
      });
    }



    return (
      <>
       <div className="breadcrumbs">
              <div className="bubble-dotted">
                <span className="dotted dotted-1"></span>
                <span className="dotted dotted-2"></span>
                <span className="dotted dotted-3"></span>
                <span className="dotted dotted-4"></span>
                <span className="dotted dotted-5"></span>
                <span className="dotted dotted-6"></span>
                <span className="dotted dotted-7"></span>
                <span className="dotted dotted-8"></span>
                <span className="dotted dotted-9"></span>
                <span className="dotted dotted-10"></span>
              </div>
              <div className="container">
              <div className="content-box">
                  <h1> Classified Ads</h1>
                  <ul className="bread-crumb clearfix">
                      <li><Link to="/"> Home </Link></li>
                      <li> Classified Ads </li>
                  </ul>
              </div>
          </div>
        </div>
       <div className="ccc">
       <div className="container">
          <div className="row">
          <div className="col-md-12">
          <div className="ccc2" onClick={this.toggleClass}><Link to="/16fe4ae43ecb50933e1c67d26612a11d" className="theme-btn style-one"><FontAwesomeIcon icon={['fas','paper-plane']} className="" />  Post Classified Ads </Link></div>
            
          {mainData}
          <div className="col-md-12">
      <div className="pagination">
        <span onClick={() => this.makeHttpRequestWithPage(1)}>&laquo;</span>
        {renderPageNumbers}
        <span onClick={() => this.makeHttpRequestWithPage(1)}>&raquo;</span>
      </div>
      </div>
      </div>

   
    </div>
    </div>
    </div>
    </>
  );
}

}

export default AllClassifieds
