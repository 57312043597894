import React,{Component} from 'react';
import {AppContext} from '../Context';
import Loader from 'react-loader-spinner';

import {toast} from "react-toastify";
import {Redirect } from "react-router-dom"; 
import { EditorState,  convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html';
let BaseURL = 'https://boescify.com/uploads/';
class Viewadstwoedit extends Component {
  static contextType = AppContext;
       constructor(props) {
              super(props);
              this.state = {
                   file: null,
                   isEditing:false,
                   invalidImage: null,
                   fileempty:false,
                   showResults: false,
                   
      editorState: EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(this.props.desc)
        )
      ),
                };
              this.onChange = this.onChange.bind(this);
              this.resetFile = this.resetFile.bind(this);
              this.categoryMode = this.categoryMode.bind(this);
               this.reader = new FileReader();
            

            }

 //text editior
 onEditorStateChange = (editorState) => {
  this.setState({
    editorState
  });
}       
componentDidMount(){
  this.fetchregister();
 }
 fetchregister = () => {
  this.context.fetchregister(this.props.userids);
}   

       //image preview       
       onChange(event) {
            //  this.setState({
            //        file: URL.createObjectURL(event.target.files[0])
            //  });

             const imageFile = event.target.files[0];
             const imageFilname = event.target.files[0].name;

             if (!imageFile) {
               this.setState({ invalidImage: 'Please select image.' });
               return false;
             }
         
             if (!imageFile.name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG|gif)$/)) {
               this.setState({ invalidImage: 'Please select valid image JPG,JPEG,PNG,GIF' });
               return false;
             }
             this.reader.onload = (e) => {
               const img = new Image();
               img.onload = () => {


                //----------------------------- Resize img code -------------------------------------------------
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 727;
                var MAX_HEIGHT = 727;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                  if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                  }
                } else {
                  if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                ctx.canvas.toBlob((blob) => {
                  const file = new File([blob], imageFilname, {
                      type: 'image/jpeg',
                      lastModified: Date.now()
                  });
                  this.setState({Resizeimg: file});
                }, 'image/jpeg', 1);


//-------------------------------------------------------------------------------------
                 this.setState({
                   selectedFile: imageFile,
                   file: URL.createObjectURL(imageFile),
                   fileempty:true,
                   invalidImage: null
                    });
               };
               img.onerror = () => {
                 this.setState({ invalidImage: 'Invalid image content.' });
                 return false;
               };
               debugger
               img.src = e.target.result;
             };
             this.reader.readAsDataURL(imageFile);
             
    
             

       }
       resetFile(event) {
              event.preventDefault();
              this.setState({ file: null });
            }

        previewbookUpdate = (classifiedids) => {
              this.context.previewbookUpdate(classifiedids,this.props.userids,this.title.value,this.state.Resizeimg,this.description.value,this.website.value,this.category.value,this.descothers.value,this.phone.value,this.email.value,this.fileempty.value);
         }
        

    //select option
    categoryMode(event) {
      if(event.target.value === 'other')
      { 
        this.setState({ showResults: true });

      }else{
        this.setState({ showResults: false });
      }
  
} 



          render(){
            const { editorState } = this.state;
            const {invalidImage} = this.state;
            
            
              return (
                  <form  className="login-forms central-meta bbb2">
                   
                  <div className="form-row">
                      <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Title <span className="adverts-form-required"> * </span> </label>
                          <input type="text" name="title" ref={(val) => this.title = val} className="form-control" placeholder="Title" required defaultValue={this.props.title}/>
                      </div>
                      <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Image <span className="adverts-form-required"> * </span> </label>
                          <input type="file" name="file" ref={(val) => this.file = val} className="form-control" placeholder="Title"  onChange={this.onChange} />
                          {invalidImage && <p className="error">{invalidImage}</p>}

                          {this.state.fileempty === false ? 

                            <input type="hidden" name="fileempty" ref={(val) => this.fileempty = val} className="form-control" defaultValue="emptyimgfields" />
                            : 
                            <input type="hidden" name="fileempty" ref={(val) => this.fileempty = val} className="form-control" defaultValue="activeimgfields" />
                            }


                      </div>
                      {this.state.file ? (
                        <div style={{ textAlign: "center" }}>
                             <img className="previewimg"  src={this.state.file} alt="UploadImage" />
                            <button onClick={this.resetFile} className="removeqqq">Remove File</button>
                        </div>
                        ) : (  <>   <img className="previewimg"  src={BaseURL+this.props.file} alt="UploadImage" />
                        </>)}
                        
                        <div className="form-group col-md-12 editor">
                        <label className="font-weight-bold"> Business / Product Description <span className="adverts-form-required"> * </span> </label>
                        <Editor
                           editorState={editorState}
                           toolbarClassName="toolbarClassName"
                           wrapperClassName="wrapperClassName"
                           editorClassName="editorClassName"
                           onEditorStateChange={this.onEditorStateChange}
                           defaultEditorState={editorState}
                           
                       />
                      <textarea style={{display:'none'}}
     disabled ref={(val) => this.description = val}
     value={draftToHtml(convertToRaw(editorState.getCurrentContent())) }
   />
                          
                        </div>
      
                      
                      <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Website Link (optional) </label>
                          <input type="text" name="website" ref={(val) => this.website = val} className="form-control" placeholder="Website Link" defaultValue={this.props.website}/>
                      </div>
                      <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Category  <span className="adverts-form-required"> * </span> </label>
                          <select  name="category" ref={(val) => this.category = val}  className="wqsss form-control" onClick={this.categoryMode} required defaultValue={this.props.category}>
                                  
                                  <option value=""> Choose the field </option>
                                  <option>Advertising</option>
                                  <option>Animation</option>
                                  <option>Art</option>
                                  <option>Books</option>
                                  <option>Business</option>
                                  <option>Business Consulting</option>
                                  <option>Content Writing</option>
                                  <option>Design</option>
                                  <option>Film Making</option>
                                  <option>Freelancers</option>
                                  <option>Illustration</option>
                                  <option>International Business</option>
                                  <option>Marketing</option>
                                  <option>Online Business</option>
                                  <option>Paintings</option>
                                  <option>SEO</option>
                                  <option>Web Design</option>
                                  <option>Web Hosting</option>
                                  <option>Wordpress</option>
                                  <option>Writers</option>
                                  <option value="other">Other</option>
                                  
                                </select>
                                { this.state.showResults ? 
                               <div>
                               <input type="text" name="descothers" className="wqsss form-control" placeholder="Enter The Your Category Name (optional)" ref={(val) => this.descothers = val} defaultValue={this.props.other} />
                               </div>
                                
                                : 
                                <div>
                               <input type="text" name="descothers" className="wqsss form-control" placeholder="Enter The Your Book Category Name (optional)" ref={(val) => this.descothers = val} style={{display:'none'}}/>
                               </div>
                               }
                      </div>
                      <hr />
                      <h3> Contact Details </h3>
                      <hr />
                      <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Phone (optional) </label>
                          <input type="text" name="phone" ref={(val) => this.phone = val} className="form-control" placeholder=" Phone Number" defaultValue={this.props.phone}/>
                      </div>     
                     <div className="form-group col-md-12">
                          <label className="font-weight-bold"> Email (optional) </label>
                          <input type="text" name="email" ref={(val) => this.email = val} className="form-control" placeholder=" Email Address " defaultValue={this.props.email}/>
                      </div>     
                     
             
                      {this.props.loadingsssedits  ? 
            <> <Loader type="ThreeDots" color="#4a33cf" height={67} width={67} style={{float: "right",textAlign: "right",width: "100%"}}/></>
            : 
            <>
            {invalidImage === null ?
                  <div className="form-group col-sm-12 text-right">
                          <button type="submit" className="theme-btn style-one" onClick={() => this.previewbookUpdate(this.props.classifiedids)}> Continue </button>
                     <button onClick={() => this.context.cancelEdit(this.props.classifiedids)} className="theme-btn style-two cansss">Cancel</button>
                      </div>
                     
                       :
                       <>
                       {invalidImage && <p className="error" style={{float:"right",textAlign: "right"}}>{invalidImage}</p>}
                       </>
                       }
                  </>
          }
                  </div>
                 
               
                  
              </form>  
             
              );
          }
      }

export default Viewadstwoedit;
