
import React, { Component } from 'react';

import {AppContext} from '../Context';

import Loader from 'react-loader-spinner'

class Forgetpassword extends Component{

    static contextType = AppContext;

  
    ForgetMail = (event) => {
        this.context.ForgetMail(event,this.useremail.value);
    }




    render(){
        const {  loadingadsss} = this.props;
        return(
            <form onSubmit={this.ForgetMail} className="login-forms">
                
                <h1> Recover your password </h1>
                <div className="clearfix"></div>
            <div className="form-row">
           
              
                <div className="form-group col-md-12">
                    <label className="font-weight-bold">Email</label>
                    <input type="email" name="useremail" ref={(val) => this.useremail = val} className="form-control" placeholder="Email"/>
                </div>
                {loadingadsss  ? 
            <>   <Loader type="ThreeDots" color="#ff7500" height={67} width={67} style={{float: "left"}}/> </>
            : 
                <div className="form-group col-sm-12 text-right">
                    <button type="submit" className="theme-btn style-three" style={{
                      marginBottom: "37px"}}> Send Me Email </button>
                </div>
    }
            </div>
           
        </form>  
        );
    }
}
export default Forgetpassword;